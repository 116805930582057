import { groupedSections, sections } from "../../Dashboard/util/accordionSections"
import { compareUserRolesToAllowedRoles } from "../../util/functions"
import { getGoogle, getPerson, searchAcrossContentServer } from "../data-sources/enums"
import { searchOptions } from "./constants"
import { searchQueries } from "./queries"
import { filterLinksWithRoles } from "./slices/buckeye-link"


const _createQuery = (gqlOptions, gqlQueries, queries) => {
    let variables = `$query: String`
    const required= queries.includes(getGoogle) || queries.includes(searchAcrossContentServer)
    if(required) {
        variables += '!'
    }
    if(queries.includes(getGoogle)) variables += `, $startIndex: String`
    if(queries.includes(getPerson)) variables += `, $nextPageLink: String`
    let query = `query ${gqlOptions.GET} (${variables}) {`

    queries.forEach((q) => {
        if(gqlQueries[q]) {
            query += gqlQueries[q]
        }
    })

    query += `
        }
    `

    return query
}

// const fieldsWithTheMostRelevantInfo = ['title', 'category', 'subcategory', 'snippet']
// const checkTags = (field, tags = []) => {
//     if(!field || !tags?.length) {
//         return []
//     }
//     const lcField = field.toLowerCase()
//     return tags.filter(tag => {
//         const lcTag = tag && tag.toLowerCase()
//         if(lcTag) {
//             return lcField.includes(lcTag)
//         }
//         return false
//     })
// }
// const checkMedCenterLinks = ({ link, tags, ...rest }) => {
//     const fieldsToCheck = fieldsWithTheMostRelevantInfo.map(field => rest[field]).filter(field => !!field)
//     let likelihoodOfMedCenterLink = 0
//     const commonMedCenterText = ["Wexner Medical Center", "Medical Center", "WMC"]
//     fieldsToCheck.forEach(field => {
//       commonMedCenterText.forEach(match => {
//         if(field.includes(match)) {
//           likelihoodOfMedCenterLink += 1
//         }
//       })
//     })
//     if(link.includes('osumc.')) {
//       likelihoodOfMedCenterLink += 1
//     }
//     return likelihoodOfMedCenterLink
//   } 
  
// const emplRoles = [...ROLE_CONDITIONS.nonStandardEmployee, ...ROLE_CONDITIONS.staffFaculty]

// const increasePriorityForNichedItems = (userRoles, linkRoles) => {
//     const userIsEmpl = compareUserRolesToAllowedRoles(userRoles, emplRoles)
//     const userIsStudent = compareUserRolesToAllowedRoles(userRoles, ROLE_CONDITIONS.student)
//     const userIStudentAndNotEmpl = userIsStudent && !userIsEmpl 
//     const userIsEmplAndNotStudent = !userIsStudent && userIsEmpl
//     const userIsEmplAndStudent = userIsEmpl && userIsStudent

//     if(userIStudentAndNotEmpl || userIsEmplAndNotStudent) {
//         const itemIsRelevantToEmployees = compareUserRolesToAllowedRoles(linkRoles, emplRoles)
//         const itemIsRelevantToStudent = compareUserRolesToAllowedRoles(linkRoles, ROLE_CONDITIONS.student)

//         const isVeryUsefulToLoggedInStudent = userIStudentAndNotEmpl && itemIsRelevantToStudent && !itemIsRelevantToEmployees
//         const isVeryUsefulToLoggedInEmployee = userIsEmplAndNotStudent && !itemIsRelevantToStudent && itemIsRelevantToEmployees
//         const isVeryUsefulToLoggedInStudentEmployee = userIsEmplAndStudent && itemIsRelevantToEmployees && itemIsRelevantToStudent
//         if(isVeryUsefulToLoggedInStudent ||isVeryUsefulToLoggedInEmployee || isVeryUsefulToLoggedInStudentEmployee) {
//           return 4
//         }
//     }
//     return 0
// }

// const checkForRoleMentioningInFields = (item, userRoles = []) => {
//   if (!item?.roles?.length) {
//     return -1;
//   }
//   let sortPriority = calculateRoleMatch(item, userRoles);
//   const nichedPriority = increasePriorityForNichedItems(userRoles, item.roles);
//   if (nichedPriority) {
//     sortPriority += nichedPriority;
//   }

//   userRoles.forEach((userRole) => {
//     const lcRole = userRole && userRole.toLowerCase();
//     if (lcRole) {
//       fieldsWithTheMostRelevantInfo.forEach((field) => {
//         const relatedText = item[field];
//         const relatedTextMentionsRole =
//           relatedText && relatedText.toLowerCase().includes(lcRole);
//         const itemIsActuallyRelevant = item.roles.includes(userRole);
//         if (itemIsActuallyRelevant) {
//           if (relatedTextMentionsRole) {
//             sortPriority += 1;
//           }
//           const tagMatch = checkTags(lcRole, item?.tags ?? []);
//           if (tagMatch?.length) {
//             sortPriority += tagMatch.length
//           }
//         }
//       });
//     }
//   });

//   return sortPriority;
// };

export function formatQuickLinks({ links = [], roles = [] }) {
    let modifiedQuickLinks = links
    if(roles?.length) {
        const linksFilteredByRole = filterLinksWithRoles(modifiedQuickLinks, roles)
        if(linksFilteredByRole?.length) {
            modifiedQuickLinks = linksFilteredByRole
        } 
        
    } else {
        const unauthLinks = modifiedQuickLinks.filter(o => !o?.roles?.length || o.roles.includes('UNAUTH'))
        if(unauthLinks?.length) {
            modifiedQuickLinks = unauthLinks
        }
    }

    return modifiedQuickLinks
}

function createSearchQuery(queries = []) {
    return _createQuery(searchOptions, searchQueries, queries)
}

export const determineDashboardQueriesAppropriateForUser = (viewKey = '', groupIdFilter = '', userAuthRoles = []) => {
    const groupFilteredSections = groupedSections[viewKey] ?? []
    const sectionQueries = []
    groupFilteredSections.forEach(({ id, query: groupQuery, sections: groupSections = [] }) => {
        const sectionMatchesFilterId = id === groupIdFilter
        if(groupQuery && groupIdFilter && sectionMatchesFilterId) {
            sectionQueries.push(groupQuery)
        }
        groupSections.forEach((sectionKey) => {
        const match = sections.find(o => o.key === sectionKey) ?? {}
        const { query, roles } = match
        const roleEligible = !roles?.length || compareUserRolesToAllowedRoles(userAuthRoles, roles)
        let canSeeSection = query &&  roleEligible
        if(groupIdFilter && canSeeSection) {
          canSeeSection = sectionMatchesFilterId
        }

        if(canSeeSection) {
          sectionQueries.push(query)
        }
      })
    })

    return sectionQueries
}
  

export {
    createSearchQuery
}
