
import { Mui, useColors } from "@osu/react-ui";
import { mergeSxStyles } from "../../styles/util";
import { COLORS } from "../../styles/icon_colors";

const colorizeAvatar = ({ theme, backgroundColor, border, color }) => {
  return {
    [`&.${Mui.avatarClasses.root}`]: {
      backgroundColor
  },
    [`.${Mui.svgIconClasses.root}, &.${Mui.avatarClasses.root} > .${Mui.typographyClasses.root}`]: {
        padding: ".1em",
        fill: color,
        color: color
    },
    border: theme.palette.type !== "dark" && `solid .1px ${border}`
  };
};

const overrides = {
  [COLORS.teal]: "#7DA4A7",
  [COLORS.blue]: "#A0DBF3",
  [COLORS.orange]: "#FF9774",
  [COLORS.brown]: "#CAB09B",
  [COLORS.green]: "#80C75B",
  [COLORS.seafoam]: "#7DC2B3",
  [COLORS.purple]: "#DAB3D1",
  [COLORS.pink]: "#FC92A5",
  [COLORS.darkGreen]: "#8edd65",
  [COLORS.yellow]: "#FFBD1A",
  [COLORS.osuGray]: "#A7B1B7"
}

function A11yIcon(props = {}) {
  const { color: propsColor, invert, children, sx, ...rest } = props;
  const theme = Mui.useTheme()
  const darkmode = theme?.palette?.mode === 'dark'
  let color = propsColor
  if(propsColor === 'primary') {
    color = theme?.palette?.primary?.main
  }
  if(darkmode && color && overrides[color]) {
    color = overrides[color]
  }
  const  all = useColors({ color, invert })
  const { saturated: backgroundColor, border, contrast: col } = all
  const isColorCode = color?.startsWith("#")
  const colorizedSx = (theme) => colorizeAvatar({
    theme,
    backgroundColor: isColorCode ? color : backgroundColor, 
    border: isColorCode ? Mui.lighten(color, .2) : border,
    color: isColorCode ? theme.palette.getContrastText(color) : col
  })
  const joinedSx = [colorizedSx, { width: "1.5em", height: "1.5em" } ]
  const combinedSx = mergeSxStyles(joinedSx, sx)

  return (
    <Mui.Avatar sx={combinedSx} {...rest}>
      {children}
    </Mui.Avatar>
  );
}

export default A11yIcon;
