import { Mui, MuiIcons } from "@osu/react-ui";
import { ARTICLE_CHANNELS, LEARN_MORE, RESOURCE_LABELS, RESOURCE_TYPES, ROLES } from "../../util/constants"
import WellnessIcon from "../../Assets/Images/Wellness"
import Wellness from "../../Wellness/components/Dashboard/";
import LinkOutSection from "../components/Resources/LinkOutSection";
import { keys } from "./enums";
import { mappings } from "../../styles/icon_colors";
import { Fragment } from "react";
import Staff from "../components/Resources/Tabs/Staff";

let TAB_IDS = {
    [RESOURCE_TYPES.STAFF_FAC]: '',
    [RESOURCE_TYPES.STUDENTS]: '',
    [RESOURCE_TYPES.WELLNESS]: '',
    [RESOURCE_TYPES.SOCIAL]: '',
    [RESOURCE_TYPES.PARENTS_FAM]: '',
}

Object.keys(TAB_IDS).forEach(key => {
    TAB_IDS[key] = key
})

const wellness = {
    tab: 0,
    resourceType: RESOURCE_TYPES.WELLNESS,
    label: RESOURCE_LABELS[RESOURCE_TYPES.WELLNESS],
    id: TAB_IDS[RESOURCE_TYPES.WELLNESS],
    Icon: WellnessIcon,
    Component: Wellness
}

const studentSections = [
    wellness,
    {
        tab: 1,
        resourceType: RESOURCE_TYPES.SOCIAL,
        label: RESOURCE_LABELS[RESOURCE_TYPES.SOCIAL],
        Icon: MuiIcons.EmojiPeople,
        id: TAB_IDS[RESOURCE_TYPES.SOCIAL],
        Component: () => <LinkOutSection
        action={{ label: <Fragment>
            {LEARN_MORE}
            <Mui.Typography component="span" variant="srOnly"> Student Organizations</Mui.Typography>
        </Fragment> }} 
        to="/student-organizations" 
        heading="Student Organizations" 
        type={keys.STUD_ORGS}
        color={mappings[keys.STUD_ORGS]}
      >
        Ohio State offers over 1,400 student organizations providing a wide range
        of opportunities to get involved, become a leader worth following, build
        organizations worth joining, and leave a lasting legacy.
    </LinkOutSection>
    },
]

const staffSections = [
    {
        tab: 0,
        resourceType: RESOURCE_TYPES.STAFF_FAC,
        label: RESOURCE_LABELS[RESOURCE_TYPES.STAFF_FAC],
        Icon: MuiIcons.Badge,
        id: TAB_IDS[RESOURCE_TYPES.STAFF_FAC],
        newsChannel: ARTICLE_CHANNELS[ROLES.EMPLOYEE],
        Component: () => <Staff />
    },
    {
        ...wellness,
        tab: 1
    }
]

const STUDENT = {
    type: "STUDENT",
    heading: "Resources",
    sections: studentSections
}

const STAFF_FAC = {
    type: "STAFF_FAC",
    heading: "Resources",
    sections: staffSections
}

const combinedType = STUDENT.type + "_" + STAFF_FAC.type

let STAFF_FAC_STUDENT = {
    type: combinedType,
    heading: "Resources",
    sections: [
        staffSections[0],
        ...studentSections
    ]
}
STAFF_FAC_STUDENT.sections = STAFF_FAC_STUDENT.sections.map((parms, index) => ({
    ...parms,
    tab: index
}))

export {
    STUDENT,
    STAFF_FAC,
    STAFF_FAC_STUDENT,
    TAB_IDS
}