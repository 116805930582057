import React, { Fragment } from "react";
import ExternalApplicationLogin from "../ExternalApplicationLogin";
import { Mui, MuiIcons } from "@osu/react-ui";
import WorkdayIcon from "../../../../Assets/Images/Workday";

const sisLogin = process.env.REACT_APP_SIS_LOGIN
const workday = process.env.REACT_APP_WORKDAY_HOME

function Staff() {
  const workdayHex = `#F49813`
  return (
    <Fragment>
      {workday && < ExternalApplicationLogin a11yIconProps={{ 
        sx: {
          [`&.${Mui.avatarClasses.root},${Mui.avatarClasses.root}.${Mui.avatarClasses.circular}.${Mui.avatarClasses.colorDefault}`]: {
            border: `solid 1px ${workdayHex}`,
            backgroundColor: Mui.lighten(workdayHex, .9)
          }
        }
       }} extAppName={"Sign in to Workday"}  Icon={WorkdayIcon} loginHref={workday} >
        This is a sign in link to the Workday home page.
      </ExternalApplicationLogin>}
      {sisLogin && < ExternalApplicationLogin extAppName={"Sign in to SIS"}  Icon={MuiIcons.Dvr} loginHref={sisLogin} >
        This is an administrative sign in to the Student Information System(SIS).
      </ExternalApplicationLogin>}
    </Fragment>
  );
}

export default Staff;
