import { createSlice } from "@reduxjs/toolkit";
import produce, { original } from 'immer'
import { graphqlApi } from "../api";
import { createBuckeyeLinkSearchResults, filterBySubcategory } from "../../../Search/util/functions";
import { RESOURCE_TYPES, STATUSES } from "../../../util/constants";
import { categories } from "../../../Search/util/categories";
import * as JsSearch from 'js-search'
import { selectAuthentication, selectAuthenticationRoleComparison } from "../../authorization/slices";
import { formatQuickLinks } from "../functions";
import SECTION_RESOURCES from "../../../Dashboard/components/Section/util/resources";

const initialState = {
  status: STATUSES.IDLE,
  query: "",
  list: [],
  _list: [],
  _categorizedResults: [],
  categorizedResults: [],
  categories: [],
  cache: [],
  featuredResults: {},
  _quickLinks: [],
  quickLinks: [],
};

const searchHelpfulLinks = ({ query = '', documents = [], customIndexes = [] }) => {
  const indexesToSearch = [...customIndexes, 'title', 'tags', 'snippet', 'category', 'subcategory']
  const index = documents?.[0]?.identifier ? "identifier" : "title"
  let search = new JsSearch.Search(index)
  indexesToSearch.map(o => search.addIndex(o))
  search.addDocuments(documents)

  return search.search(query)
}

const resortFilters = (filters = [], searchItems = []) => {
  const categoriesWithResults = searchItems.map(i => i.category)
  const filtersWithoutResults = filters.filter(f => !categoriesWithResults.includes(f.title))
  let filtersWithResults = filters.filter(f => categoriesWithResults.includes(f.title))

  if(filtersWithResults.length) {
    filtersWithResults = filtersWithResults.sort((a,b) => {
      const aIndex = categoriesWithResults.indexOf(a.title)
      const bIndex = categoriesWithResults.indexOf(b.title)
      return (aIndex > bIndex) ? 1 : -1
    })
  }  
  
  return filtersWithResults.concat(filtersWithoutResults)
}

const buckeyeLinkSlice = createSlice({
  name: "buckeyeLink",
  initialState,
  reducers: {
    search(state, action) {
      if(action?.payload?.clear) {
        state.categorizedResults = state._categorizedResults
      }
      const query = action?.payload?.query ?? ""
      
      if(!query || !state._list) {
        if(state._categorizedResults) {
          state.filters = state._filters
          state.categorizedResults = state._categorizedResults
        }
        return
      }

      const match = state.cache.find(({ query: cacheQ }) => {
        return cacheQ === query
      })
      
      if(match?.query && match?.list?.length) {
        state.categorizedResults = match.categorizedResults
        state.query = match.query
        state.list = match.list
        state.filters = match.filters

        return
      }

      const documents = original(state._list)
      const newList = searchHelpfulLinks({
        query,
        documents
      })

      const data = {
        items: newList
      }
      state.list = newList

      const { categorizedResults } = createBuckeyeLinkSearchResults(data, {
        categories: state.categories,
        query
      });

      state.categorizedResults = categorizedResults
      state.query = query
      const filters = resortFilters(state.filters, newList)
      state.filters = filters

      state.cache.push({
        query,
        list: newList,
        categorizedResults,
        filters
      })
    },
    updateReduxStore: (state, { payload }) => {
      state.status = STATUSES.SUCCESS
      const buckeyelink = payload?.data
      let data = {
        featured: buckeyelink?.featured,
        items: buckeyelink?.items
      }
      const options = { includeFilters: true }
      if(state.query) {
        options.query = state.query
      }
      const res =
        createBuckeyeLinkSearchResults(data, {
          includeFilters: true
        });

        const { items, categories, categorizedResults, filters } = res

      state._list = items;
      state._filters = filters
      state.filters = filters
      state.categories = categories;
      state.featuredResults = buckeyelink?.featured ?? {};
      state._categorizedResults = categorizedResults;
      state.categorizedResults = categorizedResults;
      state._quickLinks = payload?.data?._quickLinks ?? [];
      state.quickLinks = payload?.data?.quickLinks ?? [];
      if(payload?.roles) {
        state.roles = payload.roles
      }
    },
    updateBuckeyeLinkData: (state, { payload = {} }) => {
      const { roles } = payload
      if(roles && state?._list?.length) {
        const stampOfCurrent = (state.roles ?? []).join()
        const stampOfNew = (roles ?? []).join()
        if(stampOfCurrent !== stampOfNew) {
          return modifyBuckeyeLinkResources(state, roles)
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      graphqlApi.endpoints.getBuckeyeLinkData.matchPending,
      (state) => {
        state.status = STATUSES.LOADING
      },
      graphqlApi.endpoints.getBuckeyeLinkData.matchRejected,
      (state) => {
        state.status = STATUSES.ERROR
      }
    );
  },
});

const modifyBuckeyeLinkResources = (reduxState, roles) => {
  const countOfWellnessResourcesToRender = 3
  return produce(reduxState, (draftState) => {
    const wellnessState = draftState.featuredResults.wellness ?? []
    const newWellnessItems = wellnessState.map(resource => {
      let wellnessItems = filterBySubcategory(resource.id, draftState._list)?.items ?? []
      let remappedItems = filterLinksWithRoles(wellnessItems, roles)

      remappedItems = remappedItems.slice(0, countOfWellnessResourcesToRender)
      if(remappedItems?.length < countOfWellnessResourcesToRender) {
        const noRoleButRelated = wellnessItems.filter(({ roles }) => !roles?.length)
        remappedItems = remappedItems.concat(noRoleButRelated ?? [])
        remappedItems = remappedItems.slice(0, countOfWellnessResourcesToRender)
      }
      
      return {
        ...resource,
        items:remappedItems
      }
    })

    draftState.quickLinks = formatQuickLinks({ links: draftState?._quickLinks ?? [], roles })
    draftState.featuredResults.wellness = newWellnessItems
    draftState.roles = roles
  })
}

export const { search, updateReduxStore, updateBuckeyeLinkData } = buckeyeLinkSlice.actions;

export const selectBuckeyeLinkQuery = (state = {}) => {
  return state.buckeyeLink?.query ?? ""
};

export const selectCategories = (state = {}) => {
  return state.buckeyeLink?.categories ?? [];
};

export const selectCategorizedResults = (state = {}) => {
  const filters = state?.buckeyeLink?.filters ?? []
  let results = state.buckeyeLink?.categorizedResults
  
  if(filters?.length) {
    results = results.map(({ ...res }) => {
      const match = filters.findIndex(({ ...o }) => {
        return o.id === res.id
      })
      res.sortPriority = match < 0 ? 0 : match
      return res
    })
    results = results.sort((a, b) => {
      return (a.sortPriority > b.sortPriority) ? 1 : -1
    })
  }
  

  return results
};

export const selectFilters = (state = {}, concat = true) => {
  let fils = state?.buckeyeLink?.filters ?? []
  if(concat) {
   fils = fils.concat(categories)
  }

  return fils
}

const concatItems = (state, field, category) => {
  let list = []
  const featuredLinks = state?.buckeyeLink?.featuredResults?.[field] ?? []
  featuredLinks.forEach(({ items }) => {
    let _items = items
    if(category) {
      _items = _items.filter(({ category: cat }) => cat === category)
    }
    list = list.concat(_items)
  })
  return list
}

export const calculateRoleMatch = (item = {}, roles) => {
  const { roles: linkRoles = [] } = item
  const matchesToRoles = (linkRoles ?? []).filter(role => roles?.length && roles.includes(role))
  return matchesToRoles?.length
}

export const filterLinksWithRoles = (items = [], roles) => {
  const anyLinksWithRoles = items.find(o => o?.roles?.length)

  let filtered = []
  if(anyLinksWithRoles) {
    filtered = items.filter((item) => {
      const { roles: linkRoles = [] } = item
      const matchesToRoles = (linkRoles ?? []).filter(role => roles?.length && roles.includes(role))
      return !!matchesToRoles?.length
    })
    filtered = filtered.sort((a, b) => {
      const aRoleMatches = calculateRoleMatch(a, roles)
      const bRoleMatches = calculateRoleMatch(b, roles)
      
      return bRoleMatches - aRoleMatches
    })
  }

  return filtered
}

const filterWorkItems = (state) => {
  const list = (state?.buckeyeLink?._list ?? []).filter(item => item.subcategory === 'Work')
  return list
}

export const selectAccordionLinks = (state = {}, type, includeInternal = false) => {
  const roles = selectAuthentication(state)?.roles ?? []
  
  let links = []
  if(type === RESOURCE_TYPES.LEARNING) {
    links = concatItems(state, RESOURCE_TYPES.ACADEMIC)
  } else if (type === RESOURCE_TYPES.BUCKID) {
    links = concatItems(state, RESOURCE_TYPES.FINANCIAL)
  } else if (type === RESOURCE_TYPES.HRPROFILE) {
    links = filterWorkItems(state)
  }
  links = filterLinksWithRoles(links, roles)

  let deduped = []
  links.forEach(link => {
    const existsAlready = deduped.find(o => o.link === link.link)
    if(!existsAlready && link.link) {
      deduped.push(link)
    }
  })

  deduped = deduped.slice(0, 6)
  if(includeInternal) {
    const { internal = [] } = SECTION_RESOURCES?.[type] ?? {};

    let formattedInternalLinks = [...internal, ...deduped]
    const anyLinkHasRoles = formattedInternalLinks.find(({ roles }) => !!roles.length)
    if(anyLinkHasRoles) {
      formattedInternalLinks = formattedInternalLinks.filter(link => {
        if(link?.roles?.length) {
          return selectAuthenticationRoleComparison(state, link.roles)
        }
        return true
      })
    }

    return formattedInternalLinks
  }

  return deduped
}

export const selectAccordionLinksComparison = (a, b) => {
  const mapLinks = (arr) => arr.map(({ link }) => link).join()
  return mapLinks(a) === mapLinks(b)
}

export const selectFeaturedBuckeyeLinkData = (state,resourceType) => {
  const bl = state?.buckeyeLink
  const items = bl?.featuredResults?.[resourceType] ?? []
  
  return items
}

export const selectQuickLinks = (state) => { 
  return state?.buckeyeLink?.quickLinks ?? []
}

const reducers = {
  getBuckeyeLink: buckeyeLinkSlice.reducer,
};
export default reducers;
