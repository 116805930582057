import { useLocation } from "react-router-dom"
import { authorizationRoutes, linkablePathValues } from "../../Routes/paths"

const mdWidthPages = [
    linkablePathValues.wellness,
    linkablePathValues.feedback,
    authorizationRoutes.logout,
    authorizationRoutes.login,
]

const useContainerWidth = () => {
    const { pathname } = useLocation() ?? {}
    let pageWidth = "xl"

    if(mdWidthPages.find(str => pathname && pathname.includes(str))) {
        pageWidth = "md"
    }
    return pageWidth
}

export default useContainerWidth