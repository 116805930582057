import { ARTICLE_CHANNELS, ROLES } from "../../../util/constants";
import { buildQuery } from "../../graphql/buildQueries";
import { setContent } from "../../graphql/slices/content";
import { ARTICLES, CONTENT_SERVER_SERVICE, getArticles } from "../enums";
import { articleSection } from "./shared";

const CONSTANTS = {
  get: getArticles,
};

const get = `
    ${CONSTANTS.get}(channel: $channel) {
      ${articleSection}
  }
`;

const transformResponse = async (response = {}, { dispatch, parameters }) => {
  try {
    let sections = response?.data?.[CONSTANTS.get] ?? []
    const sectionsFromError = response?.error?.data?.[CONSTANTS.get] ?? []
      if(!sections?.length && !!sectionsFromError?.length) {
        sections = sectionsFromError
      }
      const isStudentChannel = parameters?.channel === ARTICLE_CHANNELS[ROLES.STUDENT]
      if(sections?.length && isStudentChannel) {
        dispatch(setContent(sections))
      }

      const res =  {
        data: {
          sections,
          featured: (sections ?? []).find(({ title }) => title === "University News") ?? sections?.[0]
        }
      }
      if(response?.error) {
        res.error = response?.error?.error ? response?.error?.error : response?.error
      } 
      return res
  } catch (error) {
    return error?.error ? error : error
  }
};

export const getArticlesQuery = {
  queryFn: (parameters, options) => {
    const queryBase = buildQuery(CONTENT_SERVER_SERVICE, ARTICLES)
    return queryBase.queryFn({ 
      channel: parameters?.channel || ""
    }, options)
  }
}

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse
};

export default source;
