import React from "react";
import { Mui } from "@osu/react-ui";

const Workday = Mui.createSvgIcon(
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g>
      <path d="M17.6575895,23.99784 L15.5064695,23.99784 C15.1836695,23.99784 14.9185895,23.85024 14.8309895,23.49528 L12.2395895,14.36988 L9.64818949,23.49744 C9.56058949,23.85264 9.29550949,24 8.97270949,24 L6.82158949,24 C6.46878949,24 6.26130949,23.85264 6.14610949,23.49744 C4.75806949,19.64724 3.81738949,15.82236 3.02202949,11.97204 C2.93442949,11.58468 3.10962949,11.3196 3.52002949,11.3196 L5.43594949,11.3196 C5.78874949,11.3196 6.02382949,11.49708 6.08610949,11.82216 C6.58410949,14.54964 7.17666949,17.36004 7.97202949,20.1198 L10.2683495,11.82216 C10.3559495,11.49708 10.5911495,11.3196 10.9438295,11.3196 L13.5352295,11.3196 C13.8880295,11.3196 14.1231095,11.49708 14.2107095,11.82216 L16.5070295,20.1198 C17.3023895,17.36688 17.8949495,14.54952 18.3929495,11.82216 C18.4505495,11.49708 18.6880295,11.3196 19.0431095,11.3196 L20.9590295,11.3196 C21.3694295,11.3196 21.5469095,11.58708 21.4570295,11.97204 C20.6615495,15.82236 19.7209895,19.64724 18.3353495,23.49744 C18.2154695,23.85264 18.0102695,23.99784 17.6574695,23.99784 L17.6575895,23.99784 Z" fill="#002169" fillRule="nonzero"></path>
      <path d="M7.50126949,4.89696 C8.76702949,3.62892 10.4500295,2.93268 12.2415095,2.93028 C14.0305895,2.93028 15.7113095,3.62652 16.9747895,4.89 C17.6319095,5.54712 18.1344695,6.31716 18.4665095,7.15404 C18.5425895,7.34772 18.7315895,7.47456 18.9391895,7.47456 L20.9634695,7.47456 C21.3023495,7.47456 21.5513495,7.14948 21.4545095,6.82668 C21.0025895,5.32812 20.1864695,3.95628 19.0498295,2.81736 C17.2307495,1.00296 14.8144295,4.4408921e-16 12.2437895,4.4408921e-16 C9.67314949,4.4408921e-16 7.24998949,1.00296 5.43090949,2.82432 C4.29198949,3.96324 3.47814949,5.33496 3.02850949,6.82908 C2.93166949,7.15188 3.18066949,7.47696 3.51954949,7.47696 L5.54382949,7.47696 C5.75130949,7.47696 5.94042949,7.35012 6.01650949,7.15644 C6.34614949,6.31956 6.84654949,5.5518 7.50126949,4.89696 Z" fill="#FF681D" fillRule="nonzero"></path>
      <rect x="0" y="0" width="24" height="24"></rect>
    </g>
  </g>,
  "workday"
);

const WorkdayIcon = (props) => {
  return <Workday {...props} viewBox="0 0 24 24" />;
};

export default WorkdayIcon;
