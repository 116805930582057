import { createSlice } from "@reduxjs/toolkit";
import { determineViewTypeBasedOnRoleType, getUniqueStamp, groupedSections, sections, viewTypes } from "../../../Dashboard/util/accordionSections";
import { ASSIGNMENTS, CLASSES, getAffiliations, getAllAnnouncements, getArticles, getBuckeyeLinkData, LEAVE, PAY, TODOS } from "../../data-sources/enums";
import { selectAssignmentsChips } from "./assignments";
import { selectEnrollments } from "./enrollments";
import { selectClassCalChips } from "./dashboardCalendar";
import { selectToDoChips } from "./todos";
import { selectHrStatus, selectLeaveChips, selectPayChips } from "./hrProfileData";
import { keys } from "../../../Dashboard/util/enums";
import { ROLE_CONDITIONS, STATUSES } from "../../../util/constants";
import { UNAUTH_CONSTANT } from "../../../Dashboard/util/unauthSections";
import { graphqlApi } from "../api";
import { compareUserRolesToAllowedRoles } from "../../../util/functions";
import { STAFF_FAC, STAFF_FAC_STUDENT, STUDENT } from "../../../Dashboard/util/tabSections";
import { determineDashboardQueriesAppropriateForUser } from "../functions";

let initialState = {
  roleType: UNAUTH_CONSTANT,
  expanded: false,
  status: STATUSES.IDLE,
  statuses: {},
  conditonalViewing: {
    groupIds: [],
    showName: false,
    queries: {}
  }
};

const getGroupIds = (viewKey) => {
  const correctGroupSections = groupedSections[viewKey] ?? [];
  return correctGroupSections.map(({ id }) => id).filter(o => !!o)
};

const roleTypeForApplication = (roles = []) => {
  const isStudent = compareUserRolesToAllowedRoles(roles, ROLE_CONDITIONS.student)
  const isStaffFaculty = compareUserRolesToAllowedRoles(roles, [...ROLE_CONDITIONS.staffFaculty, ...ROLE_CONDITIONS.nonStandardEmployee])
  const type =  (isStaffFaculty && isStudent)
    ? STAFF_FAC_STUDENT?.type
    : isStudent 
    ? STUDENT?.type
    : isStaffFaculty
    ? STAFF_FAC?.type
    : ''
    
    return type || UNAUTH_CONSTANT
}


const getDashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboard(state, action) {
      state.status = STATUSES.SUCCESS
      const res = action?.payload?.data
      if(Object.keys(res ?? {}).length) {
        Object.entries(res).forEach(([query, value]) => {
          if(query) {
            state[query] = {
              ...state[query] ?? {},
              ...value ?? {}
            }
          }
        })
      }
    },
    setRoleType(state, action) {
      const roles = action?.payload
      if(roles?.length) {
        const newRoleType = roleTypeForApplication(roles)
        if(state.roleType !== newRoleType) {
          state.roleType = newRoleType
          const viewKey = determineViewTypeBasedOnRoleType(newRoleType)
          state.viewKey = viewKey
          const groupIds = getGroupIds(viewKey) ?? []
          state.conditonalViewing.groupIds = groupIds
          state.conditonalViewing.showName = viewKey === viewTypes.employee
          groupIds.forEach(groupId => {
            state.conditonalViewing.queries[groupId] = determineDashboardQueriesAppropriateForUser(viewKey, groupId, roles)
          })
        }
      }
    },
    setDashExpandedKey(state, action) {
      state.expanded = action.payload
    }
  },
  extraReducers: (builder) => {
      builder.addMatcher(
        graphqlApi.endpoints.getDashboard.matchPending,
        (state) => {
          state.status = STATUSES.LOADING
        },
        graphqlApi.endpoints.getDashboard.matchRejected,
        (state) => {
          state.status = STATUSES.ERROR
        },
        graphqlApi.endpoints.getDashboard.matchFulfilled,
        (state) => {
          state.status = STATUSES.SUCCESS
        },
      );
    }
});


export const { setDashboard, setRoleType, setDashExpandedKey } = getDashboardSlice.actions

const reducers = {
  getDashboard: getDashboardSlice.reducer,
};

export const selectDashboardData = (state = {}) => {
  return state?.dashboard
};

export const selectDashboardResponseByKey = (state = {}, key) => {
  const matchingSection = sections.find(({ key: sectionKey }) => sectionKey === key)
  let data = matchingSection?.query && state?.dashboard?.[matchingSection.query]
  let chips
  if(key === ASSIGNMENTS) {
    chips = selectAssignmentsChips(state)
  }
  if(key === CLASSES) {
    chips = selectClassCalChips(state)
  }
  if(key === PAY) {
    chips = selectPayChips(state)
  }
  if(key === LEAVE) {
    chips = selectLeaveChips(state)
  }
  if(key === TODOS) {
    chips = selectToDoChips(state)
  }
  return {
    ...data ?? {},
    chips: chips ?? data?.chips,
    key
  }
};

export const selectDashboardResponseChipsByKey = (state = {}, key) => {
  return {
    chips: selectDashboardResponseByKey(state, key)?.chips,
    key
  }
}

export const selectEnrollmentData = (state = {}, courseId) => {
  const enrollmentData = (selectEnrollments(state) ?? []).find(o => o.courseId === courseId)
  return enrollmentData
}

export const compareData = (a = {}, b = {}) => {
  const noData = !a?.data && !b?.data
  if(noData) {
    return true
  }
  const aNotB = a?.data && !b?.data
  const bNotA = !a?.data && b?.data
  if(aNotB || bNotA) {
    return false
  }
  const stampA = getUniqueStamp(a?.key, a?.data)
  const stampB = getUniqueStamp(b?.key, b?.data)

  return stampA === stampB
}


export const compareChips = (a, b) => {
  const stringifyChips = (chips = []) => {
      return chips.map(({ title, snippet }) => title + snippet).join()
  }
  const noAChips = !a?.chips?.length
  const noBChips = !b?.chips?.length
  if(noAChips && noBChips) {
      return true
  }
  if((noAChips && !noBChips) || (!noAChips && noBChips)) {
      return false
  }
  const stringA = stringifyChips(a?.chips)
  const stringB = stringifyChips(b?.chips)
  return stringA === stringB
}
export const selectDashLoading = (state, key) =>{
  const hrDataLoading = selectHrStatus(state) === STATUSES.LOADING

  return {
    [keys.PAY]: hrDataLoading,
    [keys.LEAVE]: hrDataLoading,
    [keys.TIMESHEET]: hrDataLoading,
  }
}

export const selectDashboardRoleType = (state) => {
  return state.dashboard.roleType ?? UNAUTH_CONSTANT
}

export const selectDashboardViewKey = (state) => {
  return state.dashboard.viewKey
}

export const selectDashboardGroupIds = (state) => {
  return state.dashboard.conditonalViewing.groupIds
}

export const selectDashboardRenderingCondition = (state) => {
  return !!selectDashboardViewKey(state)
}

export const selectAppropriateSectionQueriesForRole = (state, groupIdFilter) => {
  const conditions = selectDashboardViewConditions(state)
  const conditionalQueries = conditions?.queries ?? { }
  let queries
  if(groupIdFilter) {
    queries = conditionalQueries[groupIdFilter]
  } else {
    queries = Object.values(conditionalQueries).flat()
  }

  return queries
}

export const selectDataLengthCheckOnDashByQuery = (state, query) => {
  const data = state.dashboard?.[query]?.data
  if(Array.isArray(data)) {
    return !!data.length
  }
  if(typeof data === 'object') {
    return !!(Object.keys(data ?? {}).length)
  }
  return !!data
}

export const selectExpandedDashKey = (state) => {
  return state.dashboard.expanded
}

export const selectDashboardViewConditions = (state) => {
  return state.dashboard.conditonalViewing ?? {}
}

const queriesThatCanAlterHeightSignificantly = [getArticles, 'getAffiliations', getAffiliations, getBuckeyeLinkData, getAllAnnouncements]

const selectGraphqlApiQueries = (state) => Object.keys(state?.graphqlApi?.queries ?? {}) 

const threshold = (2/3)

const checkIfDashboardIsPartiallyRendered = ({ state }) => {
  const queriesInRedux = selectGraphqlApiQueries(state)
  const userQueries = selectDashboardViewConditions(state)?.queries ?? {}
  const expectedCalls = Object.values(userQueries ?? {})

  if(expectedCalls?.length) {
    let preparedDashCalls = []
    expectedCalls.forEach((arrayOfQueries = []) => {
      if(arrayOfQueries?.length) {
        const match = queriesInRedux.find(reduxQuery => {
          if(!reduxQuery.startsWith('getDashboard')) {
            return false
          }
          const matchesInQueryString = arrayOfQueries.filter(currentUserQueryString => reduxQuery.includes(currentUserQueryString))
          const someOfTheCallsAreRegistered = matchesInQueryString.length > (arrayOfQueries.length * threshold)
          return someOfTheCallsAreRegistered
        })
        if(match) {
          const relatedStatus = state?.graphqlApi?.queries[match]?.status
          if( !!relatedStatus && relatedStatus !== 'pending') {
            preparedDashCalls.push(match)
          }
        }
      }
    })
    
    const atLeastSomeOfTheCallsAreInRedux = preparedDashCalls.length > (expectedCalls.length * threshold)
    return atLeastSomeOfTheCallsAreInRedux
  }
  return false
}

export const selectDashboardScreenHeightIsLoaded = (state, queriesToCheck = queriesThatCanAlterHeightSignificantly) => {
  const queriesInRedux = selectGraphqlApiQueries(state)
  const queriesFetchedByRtk = queriesInRedux.filter(query => {
    const isRelevant = queriesToCheck.some(q => {
      return q && query.startsWith(q)
    })
    return isRelevant
  })
  
  const eachRelevantQueryIsNoLongerPending = (queriesFetchedByRtk?.length) && queriesFetchedByRtk.every(query => {
    const status = query && state?.graphqlApi?.queries[query]?.status
    return !!status && status !== 'pending'
  })
  
  if(!eachRelevantQueryIsNoLongerPending) {
    return false
  }

  const affilsCalled = queriesFetchedByRtk.some(query => query.startsWith('getAffiliations') || query.startsWith(getAffiliations))
  if(affilsCalled) {
    const partiallyRendered = checkIfDashboardIsPartiallyRendered({ state })
    return partiallyRendered
  }

  return eachRelevantQueryIsNoLongerPending
}


export default reducers;
