
import { Mui } from "@osu/react-ui";
import useGridSizing from "../../../Dashboard/hooks/useGridSizing";
import { mergeSxStyles } from "../../../styles/util";
import { DARKEN, LIGHTEN, flipOnDarkMode } from "../../../util/functions";
import DashboardStylizedWrapper from '../../../Dashboard/components/Resources/StylizedWrapper'

const colorizedStyles = ({ shades, getContrastText }) => ({
  backgroundColor: shades.background,
  border: shades.border && `1px solid ${shades.border}`,
  [`& .${Mui.dividerClasses.root}`]: {
    borderColor: shades.border,
  },
  [`& .${Mui.typographyClasses.root}, .${Mui.listItemClasses.root}`]: {
    color: shades.text,
  },
  [`& 
      .${Mui.linkClasses.root}, 
      .${Mui.typographyClasses.h2}, 
      .${Mui.typographyClasses.h3}, 
      .${Mui.typographyClasses.h4}, 
      .${Mui.typographyClasses.h5}, 
      .${Mui.typographyClasses.h6}`]: {
    color: shades.heading,
    textDecorationColor: shades.heading,
  },
  [` .${Mui.linkClasses.root}:hover`]: aLinkHover(getContrastText, shades.heading)
});
export const aLinkHover = (getContrastText, color) => {
  return {
    backgroundColor: color,
    color: getContrastText(color),
    textDecoration: "none",
    padding: "1px 1px",
    "webkitTransform": `translate(-1px, -1px)`,
    "mozTransform": `translate(-1px, -1px)`,
    "oTransform": `translate(-1px, -1px)`,
    transform: `translate(-1px, -1px)`,
    borderRadius: "0.1em"
  }
}
const StylizedWrapper = ({ children, colorize, sx,className, ...rest }) => {
  const { padding } = useGridSizing();
  const theme = Mui.useTheme();
  const purple = theme.palette.colors.purple;
  const darkMode = theme.palette.mode === "dark";

  const lightenToDarken = Mui[flipOnDarkMode(darkMode, LIGHTEN)];
  const darkenToLighten = Mui[flipOnDarkMode(darkMode, DARKEN)];

  const shades = {
    root: purple,
    background: darkMode ? "#340028" : lightenToDarken(purple, 0.95),
    border: lightenToDarken(purple, .5),
    heading: darkenToLighten(purple, 0.2),
    text: darkenToLighten(purple, 0.8),
  };

  let styleSx = sx
  if(colorize) {
    styleSx = mergeSxStyles(sx, colorizedStyles({ shades, getContrastText: theme?.palette?.getContrastText }))
  }

  return (
    <DashboardStylizedWrapper
      className={`display-flex flex-direction-column padding-${padding} margin-bottom-${padding}${className ? ` ${className}` : ''}`}
      {...rest}
      sx={styleSx}
    >
      {children}
    </DashboardStylizedWrapper>
  );
};

export default StylizedWrapper;
