
import OSUAppIcon from './assets/icons/OSUAppIcon.js';
import WellnessAppIcon from './assets/icons/WellnessAppIcon.js';

const itunesBaseUrl = "https://apps.apple.com/us/app";
const playStoreBaseUrl = "https://play.google.com/store/apps/details";

const ICON_SIZE = "3rem";
const ICON_BORDER = ".5rem";

const OSU_ITUNES_ID = "446442963";
const OSU_PLAY_STORE_ID = "edu.osu.osumobile";
const WELLNESS_ITUNES_ID = "446442963";
const WELLNESS_PLAY_STORE_ID = "edu.osu.osumobile";

const OHIO_STATE_APP = {
    icon: OSUAppIcon,
    iconSize: ICON_SIZE,
    iconBorderRadius: ICON_BORDER,
    itunesId: OSU_ITUNES_ID,
    itunesUrl: `${itunesBaseUrl}/${OSU_ITUNES_ID}`,
    name: "Ohio State",
    playStoreId: OSU_PLAY_STORE_ID,
    playStoreUrl: `${playStoreBaseUrl}?id=${OSU_PLAY_STORE_ID}`,
    caption: "The Ohio State app is a must-have for all Buckeyes. " + 
        "Use it to catch the next bus, find your way around campus or grab a bite to eat. " +
        "Don't forget to sign in for personalized features including grades and account balances, " +
        "personalized news, wellness resources and more. "    
};

const WELLNESS_APP = {
    icon: WellnessAppIcon,
    iconSize: ICON_SIZE,
    iconBorderRadius: ICON_BORDER,
    itunesId: WELLNESS_ITUNES_ID,
    itunesUrl: `${itunesBaseUrl}/${WELLNESS_ITUNES_ID}`,
    name: "Ohio State: Wellness",
    playStoreId: WELLNESS_PLAY_STORE_ID,
    playStoreUrl: `${playStoreBaseUrl}?id=${WELLNESS_PLAY_STORE_ID}`,
    caption: "Find resources, connect to support, set goals and build healthy habits. " +
        "The Ohio State: Wellness app supports your mental health and well-being on campus. " +
        "Whether you're looking to find resources for yourself or someone you care about, " +
        "the Wellness app is a great place to start."
};

export const APPS = { OHIO_STATE_APP, WELLNESS_APP };