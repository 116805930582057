import { Fragment } from "react";
import StudentOrgsIcon from "../../../Assets/Images/StudentOrgs";
import A11yIcon from "../../../Common/components/A11yIcon";
import { keys } from "../../util/enums";
import { Mui, MuiIcons } from "@osu/react-ui";
import { mappings } from "../../../styles/icon_colors";
import { COLORS } from "../../../styles/icon_colors";
import BuckeyeWhiteIcon from "../../../Assets/Images/BuckeyeWhite";

const headingLevels = [...Array(6)].map((empty, idx) => idx + 1)
const Heading = ({ type, children, color: clr, divider, Icon: _Icon, a11yIconProps, headingLevelStart, id, subheading }) => {
  const icon = {
    [keys.STUD_ORGS]: StudentOrgsIcon,
    [keys.POPULAR_LINKS]: MuiIcons.QueryStats,
    [keys.SUGGESTED]: BuckeyeWhiteIcon,
  };
  const Icon = _Icon ?? icon[type];
  let color = clr
  if(mappings[type]) color = mappings[type]
  if(!color) color = COLORS.blue
  const hLevel = (headingLevelStart && headingLevels.includes(parseInt(headingLevelStart))) ? headingLevelStart : 4
  const variant = `h${hLevel}`
  const subheadingProps = {}
  if(subheading) {
    const subheadingLevel = hLevel + 1
    const exceedsHeadingTree = subheadingLevel > 6
    subheadingProps.component = exceedsHeadingTree 
      ? 'span' 
      : `h${subheadingLevel}`
    subheadingProps.variant = exceedsHeadingTree 
      ? 'subheading1' 
      : `h${subheadingLevel}`
  }

  return (
    <Fragment>
      <Mui.Typography className="display-flex align-items-center" variant={variant} id={id}>
        {Icon && (
          <A11yIcon className="margin-right-1" color={color} {...a11yIconProps ?? {}}>
            <Icon fontSize="small" />
          </A11yIcon>
        )}
        {children}
      </Mui.Typography>
      {subheading && <Mui.Typography className="padding-top-1" {...subheadingProps}>
        {subheading}
      </Mui.Typography>}
      {divider && <Mui.Divider className="margin-y-2" />}
    </Fragment>
  );
};

Heading.defaultProps = {
  divider: true
}

export default Heading;
