import { createSlice } from "@reduxjs/toolkit";
import { filterAssignments, groupAssignments, getNextUpcomingDueDate, sortAssignments } from '../../../Assignments/util/transform'
import { parseISO, isSameDay } from 'date-fns'

let initialState = {
  courses: {}
};

const _setAssignments = (state, action) =>  {
  const { assignments, courseId } = action.payload
  if(courseId) {
   state.courses[courseId] = assignments ?? []
  }
}

const getAssignmentsSlice = createSlice({
  name: "assignments",
  initialState,
  reducers: {
    setAssignments: _setAssignments,
    setAssignmentsInBulk (state, action) {
      const { courses = [], chips } = action?.payload ?? {}
      courses.forEach(({ assignments, courseId }) => {
        _setAssignments(state, {
          payload: {
            assignments,
            courseId
          }
        })
      });
      state.chips = chips
    },
    setChips(state, action) {
      const { chips } = action.payload
      state.chips = chips
   }
  }
});

export const { setAssignments, setChips, setAssignmentsInBulk } = getAssignmentsSlice.actions

export const selectAssignments = (state = {}) => {
  const courseAssignments = state?.assignments?.courses ?? {}
  let assignments = Object.values(courseAssignments).flat(1)
  assignments = sortAssignments(assignments)

  return assignments
}


export const selectAssignmentCourses= (state = {}) => {
  return Object.keys(state?.assignments?.courses ?? {})
}

export const selectAssignmentsByCourseId = (state = {}, courseId) => {
  const courseAssignments = state?.assignments?.courses ?? {}

  return courseAssignments[courseId] ?? []
}

export const selectDashboardAssignments = (state = {}) => {
  const allAssignments = selectAssignments(state)
  const filteredAssignments = filterAssignments(allAssignments);
  const groupedAssignments = Object.values(groupAssignments(filteredAssignments, "dueAt"));
  const nextUpcomingDueDate = getNextUpcomingDueDate(filteredAssignments);

  return {
    assignments: groupedAssignments,
    nextUpcomingDueDate
  }
}

export const filterAssignmentsByDueAt = (assignments = []) => {
  return assignments.filter(o => o.dueAt && isSameDay(parseISO(o.dueAt), new Date()))
}

export const selectDueTodayCount = (state = {}) => {
  const allAssignments = selectAssignments(state) ?? []
  const dueToday = filterAssignmentsByDueAt(allAssignments)

  return dueToday.length
}

export const selectAssignmentsChips = (state = {}) => {
  return state.assignments.chips
}

const reducers = {
  getAssignments: getAssignmentsSlice.reducer,
};

export default reducers