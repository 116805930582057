import { createSlice } from "@reduxjs/toolkit";
import { graphqlApi } from "../api";
import { STATUSES } from "../../../util/constants";
import { PAY } from "../../data-sources/enums";

const initialState = {
  leave: {
    eligible: false,
    balances: []
  },
  pay: {
    eligible: false,
    net: '-'
  },
  timesheet: {
    eligible: false,
    records: []
  },
  standardHours: 40
};

const _reducers = {
  setBalances(state, action) {
    state.status = STATUSES.SUCCESS
    state.leave.balances = action?.payload ?? []
    state.leave.eligible = true
  },
  setNetPay(state, action) {
    state.status = STATUSES.SUCCESS
    state.pay.net = action?.payload ?? "-"
    state.pay.eligible = true
  },
  setTimesheet(state, action) {
    state.status = STATUSES.SUCCESS
    state.timesheet.records = action?.payload ?? []
    state.timesheet.eligible = true
  },
  setChips(state, action) {
    state.status = STATUSES.SUCCESS
    state.leave.chips = action?.payload?.leave?.chips ?? []
    state.pay.chips = action?.payload?.pay?.chips ?? []
  },
  setStandardHours(state, action) {
    if(action?.payload) {
      state.standardHours = action?.payload
    }
  }
}

const hrProfileKey = 'hrProfile'
const hrProfile = createSlice({
  name: hrProfileKey,
  initialState,
  reducers: {
    ..._reducers,
    setHRStore: (state, action) => {
      const { pay, balances, timesheet, chips } = action?.payload ?? {}
      if(pay) {
        _reducers.setNetPay(state, {
          payload: pay
        })
      }
      if(balances) {
        _reducers.setBalances(state, {
          payload: balances
        })
      }
      if(timesheet) {
        _reducers.setTimesheet(state, {
          payload: timesheet
        })
      }
      _reducers.setChips(state, {
        payload: chips
      })
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      graphqlApi.endpoints.getHRData.matchPending,
      (state) => {
        state.status = STATUSES.LOADING
      },
      graphqlApi.endpoints.getHRData.matchRejected,
      (state) => {
        state.status = STATUSES.ERROR
      },
      graphqlApi.endpoints.getHRData.matchFulfilled,
      (state) => {
        state.status = STATUSES.SUCCESS
      },
    );
  }
});

export const { setChips, setEligibility, setStandardHours, setBalances, setNetPay, setTimesheet, setHRStore } = hrProfile.actions
const reducers = {
  data: hrProfile.reducer,
};

export const selectPayChips = (state = {}) => {  
  return state?.hr?.pay?.chips ?? []
};


export const selectLeaveChips = (state = {}) => {  
  return state?.hr?.leave?.chips ?? []
};


export const selectHrStatus = (state = {}) => {  
  const dashStatus = state?.dashboard?.statuses?.[PAY]
  if(dashStatus) {
    return dashStatus
  }
  
  return state?.hr?.status
};

export const selectHRLeaveEligible = (state = {}) => {  
  return state?.hr?.leave?.eligible
};

export const selectHRPayEligible = (state = {}) => {  
  return state?.hr?.pay?.eligible
};

export const selectStandardHours = (state = {}) => {  
  return state?.hr?.standardHours
};

export const selectTimesheetEligible = (state = {}) => {  
  return state?.hr?.timesheet?.eligible
};


export const selectBalances = (state = {}) => {  
  return state?.hr?.leave?.balances ?? []
};

export const selectNetPay = (state = {}) => {  
  let net = state?.hr?.pay?.net
  if(!net) {
    return '-'
  }
  if(net.startsWith('$')) {
    net = net.replace('$', '')
  }
  net = !isNaN(net) ? Number(net).toFixed(2) : net
  const formatted = '$' + net
  
  return formatted
};

export const selectTimesheets = (state = {}) => {  
  return state?.hr?.timesheet?.records ?? []
};

export const selectHrEligibility = (state = {}) => {
  return {
    leaveEligible: selectHRLeaveEligible(state), 
    payEligible: selectHRPayEligible(state), 
    timesheetEligible: selectTimesheetEligible(state),
  }
}

export default reducers;
