import { graphqlBaseQuery } from "../../util/graphql";
import AWS_LAMBDA_SERVICE from "../aws-lambda";
import CONTENT_SERVER from ".";
import { setDashboard } from "../../graphql/slices/dashboard";
import { STATUSES } from "../../../util/constants";
import { sections } from "../../../Dashboard/util/accordionSections";
import mappings from "..";

const findSchema = (q) => {
  const services = [...Object.values(CONTENT_SERVER), ...Object.values(AWS_LAMBDA_SERVICE)];
  const values = services.find(service => service.CONSTANTS.get === q)
  return values?.QUERIES?.get
}

const createDQ = (queries = []) => {
  let query = `query GetDashboard {`
  
  queries.forEach(q => {
      const schema = findSchema(q)
      if(schema) {
          query += schema
      }
  })
  
  query += `
      }
  `

  return query

}

const findMatchingTransform = (gqlQuery) => {
  let relevantTransform, relevantEndpoint
  if(!gqlQuery) {
    return false
  }

  for(const service of Object.values(mappings)) {
    if(!service) {
      break
    }
    for(const serviceKey of Object.keys(service ?? {})) {
      const endpoint = service[serviceKey]
      const matchesCurrentGqlQuery = gqlQuery && (endpoint?.CONSTANTS?.get === gqlQuery)
      if(matchesCurrentGqlQuery) {
        relevantEndpoint = endpoint
        break
      }
    }
    if(relevantEndpoint) {
      if(relevantEndpoint?.transformResponse && typeof relevantEndpoint.transformResponse === 'function') {
        relevantTransform = relevantEndpoint.transformResponse
      }
      break
    }
  }

  return relevantTransform
}

const findSectionKey = (query) => {
  return sections.find(section => section.query === query)?.key
}
export const constructStatuses = (queries = [], data, errors = []) => {
  const statuses = {}
  queries.forEach(query => {
    const key = findSectionKey(query)
    if(!key) {
      return false
    }
    const relatedData = data?.[query]
    if(!relatedData) {
      statuses[key] = STATUSES.ERROR
    } else {
      statuses[key] = STATUSES.SUCCESS
    }
  })
  if(Array.isArray(errors)) {
    Object.assign(statuses, parseErrors(errors))
  }

  return statuses
}

const parseErrors = (errors = []) => {
  let statuses = {}
  if(Array.isArray(errors)) {
    errors.forEach(({ path = [] }) => {
      (path ?? []).forEach(str => {
        const key = findSectionKey(str)
        if(str) {
          statuses[key] = STATUSES.ERROR
        }
      })
    })
  }
  return statuses
}

const transformResponsesFromDashboard = async (data = {}, {
  dispatch,
  getState,
  errors
}) => {
    const transformsNeeded = []
    let promises = []
    Object.keys(data ?? {}).forEach(gqlQuery => {
      const transformResponse = findMatchingTransform(gqlQuery)
      if(transformResponse && typeof transformResponse === 'function') {
        transformsNeeded.push({
          query: gqlQuery,
          transformResponse
        })
      }
    })
  
    for(const fields of transformsNeeded) {
      const { query, transformResponse } = fields
      let reconstructedPayloadWithRTKStructure = {}
      let querySpecificErrors
      if(Array.isArray(errors) && errors?.length) {
        querySpecificErrors = errors.filter(({ path }) => path.includes(query))
      } 
      if(querySpecificErrors?.length) {
        reconstructedPayloadWithRTKStructure = {
          error: {
            data: {
              [query]: data[query]
            },
            errors: querySpecificErrors
          }
        }
      } else {
        reconstructedPayloadWithRTKStructure = {
          data: {
            [query]: data[query]
          }
        }
      }

      if(transformResponse) {
        promises.push(new Promise(resolve => {
          const res = transformResponse(reconstructedPayloadWithRTKStructure, {
            dispatch,
            getState
          })
          resolve(res)
        }))
      }
    }
    return await Promise.allSettled(promises)
}

const formatDataForReduxStore = ({
  data
}) => {
  let shouldSetDashboard = false
  const sanitizedData = {}
  Object.keys(data ?? {}).forEach(gqlQuery => {
    const matchingSection = sections.find(o => o.query === gqlQuery)
    if(!matchingSection?.excludeDataFromChild) {
      sanitizedData[gqlQuery] = data[gqlQuery]
      shouldSetDashboard = true
    }
  })

  return {
    data: sanitizedData,
    shouldSetDashboard
  }
}

/* Dashboard */
export const getDashboardBuildQuery = {
  queryFn: async (parms = {}, { dispatch, getState }) => {
    const { queries, variables = {} } = parms || {};
      if(!queries?.length) {      
      return {
        data: {}
      }
    }
    try {
      let options = {
        query: createDQ(queries),
        variables,
      };

      const db = await graphqlBaseQuery(options);
      if(!db?.data && db.error) throw db;
      await transformResponsesFromDashboard(db.data, {
        dispatch,
        getState
      }).catch()

      const { data: sanitizedData, shouldSetDashboard } = formatDataForReduxStore({ data: db?.data })
      if(shouldSetDashboard) {
        dispatch(setDashboard({
          data: sanitizedData
        }))
      }

      return db;
    } catch (err) {
      const error = (err?.error ?? err);
      if(typeof error === "string") console.error(error);

      const data = (err?.data ?? err?.error?.data ?? {});
      let dashData = {}
      const canReturnData = Object.keys(data).length

      if(canReturnData) {
        dashData = {
          data,
          dispatch
        }

        await transformResponsesFromDashboard(data, {
          dispatch,
          getState,
          errors: err?.error?.errors
        })
        const { data: sanitizedData, shouldSetDashboard } = formatDataForReduxStore(dashData)
        if(shouldSetDashboard) {
          dispatch(setDashboard({
            data: sanitizedData
          }))
        }
        return {
          data,
          error: err?.error ?? err
        }
      }
      if(err?.error) return err
      return {
        error: err
      }
    }
  },
};