import { Link } from "react-router-dom";
import { buckeyelink } from "../util/constants";
import { Mui } from '@osu/react-ui';
import { linkablePathValues, pageTitles } from "../Routes/paths";

export const navOverrideStyles = (theme, spacing) => {
  return {
    " #osu-navbar.bux-resets .bux-container, footer.bux-footer > .bux-container": {
      [theme.breakpoints.up("xl")]: {
        maxWidth: theme.breakpoints.values.xl,
        paddingY: 0,
        paddingLeft: theme.spacing(spacing),
        paddingRight: theme.spacing(spacing),
      },
      margin: 0,
      paddingLeft: theme.spacing(spacing),
      paddingRight: theme.spacing(spacing),
      width: "100%",
    },
    " footer.bux-footer": {
      paddingBottom: theme.spacing(spacing * 3)
    },
    " #osu-navbar.bux-resets .bux-container #osu-navname-block img": {
      maxHeight: "2rem",
    },
    " #osu-navname-block, .bux-osu-nav__osu-logo-img": {
     padding: 0,
   },
    "#osu-navlinks a": {
      fontWeight: 700,
    },
    "#osu-navlinks li:last-of-type": {
      marginRight: 0,
    },
  };
};

export const footerProps = {
  address: buckeyelink.address,
  phone: buckeyelink.phone,
  footerLinks: [
    {
      href: "https://go.osu.edu/privacy",
      label: "Privacy Statement",
    },
    {
      href: "https://go.osu.edu/non-discrimination-notice",
      label: "Non-discrimination Notice",
    }
  ],
  ContactProps: {
    hideEmail: true,
    children: <div className="contactLinkContainer display-flex">
      <a className="bux-link contactLink" href="https://help.osu.edu/">help.osu.edu</a>
      <Mui.Link
        to={linkablePathValues.feedback}
        component={Link}
        className="bux-link contactLink margin-0 padding-0"
        underline="none"
      >
        {pageTitles.feedback}
      </Mui.Link>
    </div>
  }
}

export const linkOverrides = [
  {
    href: "https://www.osu.edu/map",
    children: "Map",
  },
  {
    href: "https://email.osu.edu/",
    children: "Webmail",
  },
  {
    href: "https://www.osu.edu/search/",
    children: "Search Ohio State",
  },
]