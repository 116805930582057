import { graphqlApi } from "../../graphql/api";
import { initializeColorList } from "../../graphql/slices/dashboardCalendar";
import { initializeEnrollmentResponse } from "../../graphql/slices/enrollments";
import { getEnrollments } from "../enums";
import { chips } from "./shared";
const CONSTANTS = {
  get: getEnrollments,
};

const get = `
    ${CONSTANTS.get} {
      ${chips}
      data {
        name
        courseId
        term
        termCode
        termName
        includeAssignments
        includeFiles
        includeGrades
        includeUsers
        itemHash
      }

    }
`;

const transformResponse =  (response, { dispatch, getState }) => {
  const { data  = []} = response?.data?.[getEnrollments] ?? {}
  const courseNames = {}
  let queue = []

  data.forEach(({ courseId, name, ...rest }) => {
    const state = getState()
    const cachedCourses = state?.assignments?.courses
    const existingCourseAssignments = cachedCourses?.[courseId]
    if(courseId && !existingCourseAssignments?.length) {
      queue.push(courseId)
    }
    if(courseId) {
      courseNames[courseId] = name ?? ""
    }
  })
  
  dispatch(initializeEnrollmentResponse({
    enrollments: data,
    courses: courseNames
  }))
  
  if(!queue.length) {
    dispatch(initializeColorList())
  } else {
    dispatch(graphqlApi.endpoints.getAssignmentsByQueue.initiate({
      queue
    }))
  }
  return response
}

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse
};


export default source;
