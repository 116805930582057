import { lazy } from "react";
import LogIn from "../Authentication/components/LogIn";
import LogOut from "../Authentication/components/LogOut";
import { ROLES } from "../util/constants";
import Unauthorized from "./views/Unauthorized";
import { keys, labels } from "../Dashboard/util/enums";
import { alterTitleToId } from "../util/functions";
import { createSearchPageTitle } from "./util";
import Feedback from "../Feedback/components";

const Dashboard = lazy(() =>
  import("../Dashboard/components/Views")
);

const Search = lazy(() =>
  import("../Search/components")
);

const StudentOrganizations = lazy(() =>
  import("../StudentOrgs/components")
);

const IndividualOrgSearch = lazy(() =>
  import("../StudentOrgs/components/IndividualOrgSearch")
);

const Wellness = lazy(() => import('../Wellness/components'))
const News = lazy(() => import('../News/components/Aggregated'))

const CarmenClass = lazy(() => import('../CarmenClass/components'))

const CarmenClasses = lazy(() => import('../CarmenClass/components/Classes'))

const AnnouncementsPage = lazy(() =>
  import("../AnnouncementsPage/components")
);

const FinalGrades = lazy(() =>
  import("../FinalGrades/components")
);

const ToDos = lazy(() =>
  import("../ToDos/components")
);

const authorizationRoutes = {
  login: "/login",
  logout: "/logout",
  unauthorized: "/unauthorized",
};

const authenticatedPaths = [
  {
    title: "Sign In",
    path: authorizationRoutes.login,
    Component: LogIn,
  },
  {
    title: "Sign Out",
    path: authorizationRoutes.logout,
    Component: LogOut,
  },
  {
    title: "Log In",
    path: authorizationRoutes.login,
    Component: LogIn,
  },
  {
    title: "Unauthorized",
    path: authorizationRoutes.unauthorized,
    Component: Unauthorized,
  },
]

const linkablePathValues = {
  dashboard: '/',
  search: '/search',
  studentOrgs: '/student-organizations',
  wellness: `/${keys.WELLNESS.toLowerCase()}`,
  articles: `/articles`,
  carmenClass: '/carmen-class',
  announcements: `/announcements`,
  finalGrades: `/final-grades`,
  toDos: '/to-do-list',
  feedback: '/feedback',
}
export const pageTitles = {
  carmenClass: 'My Carmen Courses',
  finalGrades: 'Final Grades',
  toDos: 'To-Do List',
  feedback: 'Submit Website Feedback'
}

const PATHS = [
  {
    paths: [
      {
        title: 'Home',
        path: linkablePathValues.dashboard,
        Component: Dashboard,
      },
      {
        title: (props) => createSearchPageTitle({ searchParams: props?.searchParams }),
        path: linkablePathValues.search,
        Component: Search,
      },
      {
        title: (props) => {
          const title = alterTitleToId(props?.params?.type ?? "", false)

          return title ?? labels[keys.STUD_ORGS]
        },
        path: linkablePathValues.studentOrgs + "/:type",
        Component: IndividualOrgSearch,
      },
      {
        title:labels[keys.STUD_ORGS],
        path: linkablePathValues.studentOrgs,
        Component: StudentOrganizations,
      },
      {
        title: labels[keys.WELLNESS] + ` Resources`,
        path: linkablePathValues.wellness + "/:title?",
        Component: Wellness,
      },
      {
        title: "Articles",
        path: linkablePathValues.articles + "/:category?",
        Component: News,
      },
      {
        title: pageTitles.feedback,
        path: linkablePathValues.feedback,
        Component: Feedback,
      },
    ],
  },
  {
    allowedRoles: [ROLES.STUDENT],
    paths: [
      {
        title: pageTitles.carmenClass,
        path: linkablePathValues.carmenClass,
        Component: CarmenClasses,
      },
      {
        title: "Assignments",
        path: linkablePathValues.carmenClass + "/:id?",
        Component: CarmenClass,
      },
      {
        title: pageTitles.finalGrades,
        path: linkablePathValues.finalGrades,
        Component: FinalGrades,
      },
      {
        title: pageTitles.toDos,
        path: linkablePathValues.toDos,
        Component: ToDos,
      }
    ],
  },
  {
    allowedRoles: [ROLES.STUDENT, ROLES.EMPLOYEE],
    paths: [
      {
        title: "Announcements",
        path: "/announcements",
        Component: AnnouncementsPage,
      }
    ],
  },
];



export { authenticatedPaths, authorizationRoutes, linkablePathValues, PATHS as default };
