
import { Mui } from "@osu/react-ui";
import useGridSizing from "../../hooks/useGridSizing";
import usePaperStyleProps from "../../../Common/hooks/usePaperStyleProps";
import { mergeSxStyles } from "../../../styles/util";

const createStylizedWrapperStyles = (theme, GAP) => {
  const darkMode = theme?.palette?.mode === "dark"
  const sx = {
    padding: 2,
    marginBottom: GAP,
    display: 'flex',
    flexDirection: 'column'
  }
  if(darkMode) {
    sx.border = `solid 1px ${theme.palette.divider}`
  }
  return sx
}

function StylizedWrapper({ children, sx }) {
  const { gap: GAP } = useGridSizing();
  const { nestedDetail } = usePaperStyleProps()
  return (
    <Mui.Paper
      {...nestedDetail}
      sx={mergeSxStyles(theme => createStylizedWrapperStyles(theme, GAP), sx)}
    >
      {children}
    </Mui.Paper>
  );
}

export default StylizedWrapper;
