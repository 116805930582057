import { graphqlBaseQuery } from "../util/graphql";
import { createSearchQuery } from "./functions";
import SERVICE_MAP from "../data-sources/index";
import { capitalizeFirstLetter, deepCopy } from "@osu/react-ui"
import { getGoogle, getPerson } from "../data-sources/enums";

const validateQueryPage = (page = []) => Array.isArray(page) && page.length > 0 ? page[0] : {}
export function determinePagination(queries = {}) {
  const nextPage = validateQueryPage(queries?.nextPage) ?? {}

  return nextPage?.startIndex ?? undefined
}

function mergeGoogleResults(cachedData = {}, newData = {}) {
  const cachedNextPage = validateQueryPage(cachedData?.queries?.nextPage) ?? {}
  const newPreviousPage = validateQueryPage(newData?.queries?.previousPage) ?? {}
  
  if((newPreviousPage?.startIndex + newPreviousPage?.count) === cachedNextPage?.startIndex) {
    const oldItems = cachedData?.items
    const newItems = newData?.items
    
    return {
      items: oldItems.concat(newItems),
      queries: newData.queries
    }
  }
}

export const getSearchResultsBuildQuery = {
  queryFn: async (parms = {}, { getState }) => {
      const { queryString, graphQLQueries, showMore } = parms || {};
      if(!queryString || !graphQLQueries?.length) {
        return {
          data: {}
        }
      }
      const state = getState()
      
      const match = (state?.search?.cache ?? []).find(cacheItem => cacheItem.query === queryString )
      const cachedGoogleData = match?.payload?.[getGoogle] ?? {}
      const cachedPersonData = match?.payload?.[getPerson] ?? {}
      const queries = cachedGoogleData?.queries

      let startIndex = determinePagination(queries) ?? undefined
      const schemaVariables = {}
      if(!showMore && (cachedGoogleData?.items && cachedPersonData?.items)) {
        return {
          data: match?.payload
        }
      } else if(graphQLQueries.includes(getGoogle)) {
        schemaVariables.startIndex = startIndex
      } else if(graphQLQueries.includes(getPerson)) {
        schemaVariables.nextPageLink = cachedPersonData.nextPageLink ?? ""
      }
      let options = {
        query: createSearchQuery(graphQLQueries),
        variables: {
          query: queryString,
          ...schemaVariables
        },
      };

      let response
      try {
        response =  await graphqlBaseQuery(options);
      } catch (error) {
        const keys = Object.keys(error?.data ?? {}) ?? []
        keys.forEach(key => {
          const items = error?.data?.[key]?.items
          if(!response?.data) response = { data: {} }
          if(items?.length) {
            response.data[key] = error.data[key]
          }
        })
        if(!Object.keys(response ?? {}).length) {
          throw error
        }
      }
      if(match?.payload) {
        let res = {
          data: deepCopy({}, match.payload)
        }
        if(response?.data?.[getGoogle] && startIndex !== undefined) {
          const mergedResponse = mergeGoogleResults(
            cachedGoogleData, 
            response?.data?.[getGoogle]
          )
          
          res.data[getGoogle] = mergedResponse
        }
        if(response?.data?.[getPerson] && cachedPersonData.nextPageLink) {
          let cachedItems = cachedPersonData.items
          cachedItems = cachedItems.concat(response?.data?.[getPerson]?.items ?? [])
          if(!res.data[getPerson]) {
            res.data[getPerson] = {}
          }
          Object.assign(res.data[getPerson], response?.data?.[getPerson])
          res.data[getPerson].items = cachedItems
        }
        return res
      }

      return response
    }
};

export const buildQuery = (service = "", query = "", options = {}) => {
  const {gqlQueryType = "query", queryType = "get", queryParameters} = options;
  const buildVariablesString = async (variables) => {
    if(queryParameters) {
      return queryParameters
    }
    const RETURN = "\n"
    //right now defaulting to "String!"" but could probably be something else but would need logic to determing what that would be - JMM
    const variablesKeys = Object.keys(variables).map((key) => `$${key}: String!`).reduce((combinedString, varString) => combinedString + RETURN + varString )
    return variablesKeys;
  }
  const buildFunction = async (parameters, options) => {
    try {
      let validParametersExist = false;
      if(parameters){
        validParametersExist = Object.prototype.toString.call(parameters) === "[object Object]" && Object.keys(parameters).length > 0
        if (!validParametersExist) {
          console.warn(`The parameters you passed: ${parameters} are not valid they must be in an object with key value pairs. Attempting to continue your query.`)
        }
      }

      const correspondingService = SERVICE_MAP?.[service]
      if(!correspondingService) {
        throw new Error(`Unexpected service type, expected one of: ${Object.keys(correspondingService).join(",")}`)
      }
      const correspondingQuery = correspondingService?.[query]
      if(!correspondingQuery) {
        throw new Error(`Unexpected query type, expected one of: ${Object.keys(correspondingQuery).join(",")}`)
      }
      const { QUERIES, CONSTANTS, transformResponse } = correspondingQuery
      if(!CONSTANTS[queryType]) {
        throw new Error(`This query does not contain a ${queryType} constant, required to properly transform data. Please make sure the query has a CONSTANTS object exported, with a ${queryType} string field.`)
      }
      const capitalizedQuery = capitalizeFirstLetter(CONSTANTS[queryType])
      let gqlQuery =  `${gqlQueryType} ${capitalizedQuery} ${validParametersExist ? `(${await buildVariablesString(parameters)})` : "" }{
        ${QUERIES[queryType]}
      }`
  
      let queryParams = { query: gqlQuery }
      if(validParametersExist) {
        queryParams.variables = parameters;
      }

      const response = await graphqlBaseQuery(queryParams);
      
      if(typeof transformResponse === "function") {
        return await transformResponse(response, {
          parameters,
          ...options
        })
      }
      return response
    } catch (error) {
      console.error(error)
      return {
        error
      }
    }
  }
  return {
    queryFn: async (parameters, options) => {
      return await buildFunction(parameters, options)
    }
  }
}
