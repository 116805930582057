import { createSlice } from "@reduxjs/toolkit";
import { ARTICLE_CHANNELS, RESOURCE_TYPES, ROLES } from "../../../util/constants";

const initialState = {
  sections: [],
  channels: [
    ARTICLE_CHANNELS[ROLES.STUDENT], 
    ARTICLE_CHANNELS[ROLES.EMPLOYEE], 
    ARTICLE_CHANNELS[RESOURCE_TYPES.WELLNESS]
  ]
};

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setContent(state, action) {
      const sections = action?.payload ?? []
      if(sections?.length) {
        state.sections = sections
        let articleIds = [], sectionIds = []
        sections.forEach(({ sectionId, articles = [] }) => {
          const aIds = articles.map(({ identifier }) => identifier)
          articleIds = articleIds.concat(aIds)
          sectionIds.push(sectionId)
        })
        state[ARTICLE_CHANNELS[ROLES.STUDENT]] = {
          articleIds,
          sectionIds
        }
      }
    },
    setChannels(state, action) {
      const hasChannels = action?.payload?.channels?.length
      if(hasChannels && action?.payload?.append) {
        action.payload.channels.forEach(newChannel => {
          if(!state.channels.includes(newChannel)) {
            state.channels.push(newChannel)
          }
        })
      } else if (hasChannels) {
        state.channels = action.payload.channels
      }
    }
  }
});

export const { setContent, setIdentifiers, setChannels } = contentSlice.actions
const reducers = {
  content: contentSlice.reducer,
};

export const selectContent = (state = {}) => {  
  const sections = state?.content?.sections ?? []

  return sections
};

export const selectRoleSpecificChannels = (state = {}) => {
  let channels = state?.content?.channels ?? []
  if(!channels?.length) {
    channels = initialState.channels
  }
  return channels.filter(o => !!o)
};

export default reducers;
