import React from 'react'
import { Mui } from '@osu/react-ui';

const headings = [...Array(6)]
const variants = headings.map((empty, i) => 'h' + (i +  1)).concat(['p', 'span'])

const Informational = ({info = "", infoComponent="h2", children = false, childrenFirst = false, direction = "row", className,...rest}) => {
  const classesObj = {display: "flex", flexDirection: {direction}, ...rest}
  if(direction === "column"){ classesObj.alignItems = 'flex-start'}
  let component = infoComponent
  if(!variants.includes(component)) {
    component = 'span'
  }

  return (
    <Mui.Container sx={classesObj} className={className}>
        {childrenFirst && children}
        <Mui.Typography component={component} variant={infoComponent}>
            {info}
        </Mui.Typography>
        {!childrenFirst && children}
    </Mui.Container>
  )
}

export default Informational