import { Fragment } from "react";
import LinkOutSection from "../LinkOutSection";
import BuckeyeWhiteIcon from "../../../../Assets/Images/BuckeyeWhite";
import BuckeyeIcon from "../../../../Assets/Images/Buckeye";
import { APPLICANT_CENTER_HREF } from "../../../../util/constants";
import { Mui } from "@osu/react-ui";

function StudentResourceTab() {
  const theme = Mui.useTheme()
  const darkMode = theme?.palette?.mode === "dark"

  return (
    <Fragment>
      <LinkOutSection
        Icon={darkMode ? BuckeyeWhiteIcon : BuckeyeIcon }
        heading={`Prospective Students`}
        color={theme.palette.brand.gray[darkMode ? "darker" : "lighter"][60]}
      >
        Sign in to your <Mui.Link underline="hover" href={APPLICANT_CENTER_HREF}>Applicant Center</Mui.Link> to check the status of your application and all requirements.
      </LinkOutSection>
    </Fragment>
  );
}

export default StudentResourceTab;
