import { keys, labels } from "../Dashboard/util/enums"
import { addMonths } from 'date-fns'

const FORCED_AUTHENTICATION = process.env.REACT_APP_FORCED_AUTHENTICATION === "true"
const OSU_NAV_ID = 'osu-navbar'
const MAIN_ID = 'page-content'
const ACCESSIBLE_REFLOW_HEIGHT = `399px`
const TOC_WIDTH = `15rem`
const NAVBAR_WRAPPER_ID = "app-header"
const APPLICATION_NAME = 'Buckeye Link'
const APP_MAX_WIDTH = "xl"
const STATUSES = {
    IDLE: "idle",
    LOADING: "loading",
    ERROR: "error",
    SUCCESS: "success",
    CLEARED: "cleared",
    UNAUTH: "unauth",
    COGNITO_FAILURE: "COGNITO_FAILURE",
}

const ROLES = {
    STUDENT: 'STUDENT',
    PARENT: 'PARENT',
    FACULTY: 'FACULTY',
    STAFF: 'STAFF',
    EMPLOYEE: 'EMPLOYEE',
    ALUMNI: 'ALUMNI',
    ALL: 'ALL',
    NON_STANDARD_EMPLOYEE: 'NON_STANDARD_EMPLOYEE',
    MED_CENTER: 'OSU Physicians',
}

export const ROLE_CONDITIONS = {
    student: [ROLES.STUDENT],
    staffFaculty: [ROLES.FACULTY, ROLES.STAFF, ROLES.EMPLOYEE],
    alumni: [ROLES.ALUMNI],
    nonStandardEmployee: [ROLES.NON_STANDARD_EMPLOYEE]
}


const BROWSER_FONT_SIZE = window.getComputedStyle(document.body,null).getPropertyValue("font-size")

export const AUTH_MODE = {
    AMAZON_COGNITO_USER_POOLS: "AMAZON_COGNITO_USER_POOLS",
    AWS_IAM: "AWS_IAM"
};

const RESOURCE_TYPES = {
    ACADEMIC: "academics",
    WELLNESS: "wellness",
    FINANCIAL: "financial",
    SOCIAL: "social",
    STUDENTS: "students",
    PARENTS_FAM: "parentsFamilies",
    STAFF_FAC: "staffFaculty",
    LEARNING: 'LEARNING',
    BUCKID: 'BUCKID',
    HRPROFILE: 'HRPROFILE',
    HOLDS: 'HOLDS',
    BALANCEACCOUNTS: 'BALANCEACCOUNTS',
}

const RESOURCE_LABELS = {
    [RESOURCE_TYPES.ACADEMIC]: "Academic",
    [RESOURCE_TYPES.WELLNESS]: labels[keys.WELLNESS],
    [RESOURCE_TYPES.FINANCIAL]: "Financial",
    [RESOURCE_TYPES.SOCIAL]: "Social",
    [RESOURCE_TYPES.STUDENTS]: "Students",
    [RESOURCE_TYPES.PARENTS_FAM]: "Parents & Families",
    [RESOURCE_TYPES.STAFF_FAC]: "Staff & Faculty"
}

const CAMPUSES = {
    COLUMBUS: "Columbus"
}

const COLORS = {
    red: "red",
    gray: "gray",
    blue: "blue",
    orange: "orange",
    green: "green",
    brown: "brown",
    pink: "pink",
    purple: "purple",
    teal: "teal",
    yellow: "yellow"
}
export const GOOGLE_SEARCH_LIMIT = 20
export const LEARN_MORE = "VIEW ALL"
export const APPLICANT_CENTER_HREF = "https://appstatus.osu.edu/"
export {
    FORCED_AUTHENTICATION,
    OSU_NAV_ID,
    MAIN_ID,
    ACCESSIBLE_REFLOW_HEIGHT,
    TOC_WIDTH,
    NAVBAR_WRAPPER_ID,
    STATUSES,
    ROLES,
    APPLICATION_NAME,
    BROWSER_FONT_SIZE,
    RESOURCE_TYPES,
    RESOURCE_LABELS,
    CAMPUSES,
    APP_MAX_WIDTH,
    COLORS
}

export const buckeyelink = {
    address: {
        orgName: "Buckeye Link - Office of Strategic Enrollment Management",
        addressLine1: "Student Academic Services",
        addressLine2: "281 W. Lane Ave",
        city: "Columbus",
        state: "OH",
        zip: "43210",
    },
    phone: {
        label: "614-292-0300",
        href: "tel:614-292-0300",
    },
    fax: {
        label: "Fax: 614-292-5587",
        href: "tel:614-292-5587",
    },
}

export const CACHE_KEYS = {
    darkMode: 'darkMode',
    deviceSettings: 'deviceSettings',
    on: 'on',
    off: 'off',
}

export const CACHE_OPTIONS = {
    [CACHE_KEYS.darkMode]: {
        [CACHE_KEYS.deviceSettings]: 'device settings',
        [CACHE_KEYS.on]: 'on',
        [CACHE_KEYS.off]: 'off',
    }
}

let sixMonthsFromNow = new Date()
sixMonthsFromNow = addMonths(sixMonthsFromNow, 6)
sixMonthsFromNow = sixMonthsFromNow.getTime()

export const SIX_MONTHS_FROM_NOW = sixMonthsFromNow

export const CARMEN = {
    DASHBOARD: {
        label: 'CarmenCanvas Dashboard',
        href: 'https://osu.instructure.com/',
    }
}

export const todos = {
    individual: 'to-do',
    page: 'To-Do',
    aria: {
        plural: `to-do's`
    }
}

export const HR = {
    ACCRUAL: {
        href: 'https://hr.osu.edu/benefits/leave/accrual/',
        label: 'Vacation and Sick Time Accrual'
    }
}

const baseUrl = (process.env.REACT_APP_WORKDAY_BASE_URL) || 'https://www.myworkday.com/osu/d/task/'

export const WORKDAY = {
    CALENDAR: {
        label: 'Time Off (Absence Calendar)',
        href: baseUrl + '2997$11831.htmld'
    },
    PAYSLIPS: {
        label: 'My Payslips',
        href: baseUrl + '2997$1475.htmld'
    },
    TIMESHEET: {
        label: 'My Timesheet',
        href: baseUrl + '2997$4767.htmld'
    }
}

export const ARTICLE_CHANNELS = {
    [ROLES.EMPLOYEE]: 'compass',
    [ROLES.ALUMNI]: 'alumni',
    [ROLES.ALL]: 'ohiostate',
    [ROLES.STUDENT]: 'ohiostate',
    [RESOURCE_TYPES.WELLNESS]: 'wellness',
}

export const ARTICLE_CHANNEL_ROLE_LABELS = {
    [ARTICLE_CHANNELS[ROLES.EMPLOYEE]]: RESOURCE_LABELS[RESOURCE_TYPES.STAFF_FAC],
    [ARTICLE_CHANNELS[ROLES.ALUMNI]]: 'Alumni',
    [ARTICLE_CHANNELS[ROLES.STUDENT]]: RESOURCE_LABELS[RESOURCE_TYPES.STUDENTS],
    [ARTICLE_CHANNELS[RESOURCE_TYPES.WELLNESS]]: RESOURCE_LABELS[RESOURCE_TYPES.WELLNESS],
}