import { Mui } from "@osu/react-ui";
import Heading from "../Shared/Heading";
import useGridSizing from "../../hooks/useGridSizing";
import usePaperStyleProps from "../../../Common/hooks/usePaperStyleProps";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../../services/authorization/slices";

function Name({ sx, className: cls, elevation: el }) {
  const { gap: GAP } = useGridSizing();
  const { accordionChildren } = usePaperStyleProps();
  const firstName = useSelector(state => {
    return selectAuthentication(state)?.user?.firstName
  });
  if (!firstName) {
    return false;
  }
  const elevation = el ?? accordionChildren.elevation

  let heading = "Hi";
  heading += `, ${firstName ?? "Buckeye"}`;
  heading += "!";

  let className = `display-flex align-items-center padding-y-${GAP} padding-x-2`
  if(cls) className += ` ${cls}`

  return (
    <Mui.Box 
      sx={sx} 
      component={Mui.Paper} 
      elevation={elevation}
      className={className}>
      <Heading
        elevation={elevation}
      >
        {heading}
      </Heading>
    </Mui.Box>
  );
}

export default Name;