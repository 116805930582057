import { sortAssignments } from "../../../Assignments/util/transform";
import { filterAssignmentsByDueAt, selectDueTodayCount, setAssignmentsInBulk, setChips } from "../../graphql/slices/assignments";
import { setAssignmentCourses, initializeDashboardForAssignments } from "../../graphql/slices/dashboardCalendar";
import { selectEnrollmentName } from "../../graphql/slices/enrollments";
import { graphqlBaseQuery } from "../../util/graphql";
import { getAssignments } from "../enums";
import { chips } from "./shared";

const CONSTANTS = {
  get: getAssignments,
};

const get = `
    ${CONSTANTS.get}(courseId: $courseId) {
      ${chips}
      data {
      id
      description
      dueAt
      unlockAt
      lockAt
      pointsPossible
      gradingType
      createdAt
      updatedAt
      submittedAt
      courseId
      name
      hasSubmittedSubmissions
      htmlURL
      lockedForUser
      lockExplanation
      submissionsDownloadURL
      enrollmentName
    }
  }
`;

const transformResponse = (response, { getState, dispatch, parameters }) => {
  const state = getState()

  let assignments = (response?.data?.[getAssignments]?.data ?? [])
    .filter(o => o.courseId === parameters?.courseId)
    .map(o => {
      o.enrollmentName = selectEnrollmentName(state, parameters?.courseId)
      return o
    })

  assignments = sortAssignments(assignments)
  dispatch(setAssignmentCourses({ assignmentsDue: assignments.filter(a => a.dueAt) }))
  const newDueTodayCount = selectDueTodayCount(getState())
  dispatch(setChips({
    chips: [{
      title: "Due Today",
      snippet: `${newDueTodayCount ?? 0}`,
      description: "Assignments due today",
    }]
  }))

  return response
}

const source = {
  CONSTANTS,
  transformResponse,
  QUERIES: {
    get,
}};

export const getAssignmentsByQueueQuery  = {
  queryFn: async (parms = {}, { dispatch, getState }) => {
    const state = getState()
    const { queue = [] } = parms || {};
    if(!queue?.length) {
      return {
        data: {}
      }
    }
    
    let graphqlPromises = []
    queue.forEach(courseId => {
      const queryToCall = ( {
        query: `query GetAssignmentsV2($courseId: String!) {
            ${source.QUERIES.get}
          }
        `,
        variables: {
          courseId
        }
      })

      const cb = async () => {
        try {
          return {
            courseId,
            ...await graphqlBaseQuery(queryToCall)
          }
        } catch (error) {
          console.error(error)
          const msg = error?.message ?? error 
          return {
            error: typeof msg === 'string' ? msg : `Unable to get assignments for course ${courseId}` 
          }
        }
      }
      graphqlPromises.push(cb())
    })

    const callback = (responses  = []) => {
      const responseData = {}
      let courses = [],  dashboardCourses = [], allDueAssignments = []
      responses.forEach((response) => {
        const { value = {} } = response ?? {}
        const { courseId, data } = value ?? {}
        if(courseId) {
          responseData[courseId] = {
            chips: data?.[getAssignments]?.chips,
            data: data?.[getAssignments]?.data,
          }
        }
          let assignments = (data?.[getAssignments]?.data ?? [])
              .filter(o => o.courseId === courseId)
              .map(o => {
                o.enrollmentName = selectEnrollmentName(state, courseId)
                return o
              })
              assignments = sortAssignments(assignments)
          courses.push({
            courseId,
            assignments,
          })
          const dueAtAssignments = assignments.filter(a => a.dueAt)
          allDueAssignments = allDueAssignments.concat(dueAtAssignments)
          if(dueAtAssignments?.length) {
            dashboardCourses.push({
              assignmentsDue: dueAtAssignments
            })
          }
      })
      const newDueTodayCount = filterAssignmentsByDueAt(allDueAssignments)?.length
      dispatch(setAssignmentsInBulk({ 
        courses,
        chips: [{
          title: "Due Today",
          snippet: `${newDueTodayCount ?? 0}`,
          description: "Assignments due today",
      }]
      }))
      dispatch(initializeDashboardForAssignments({ 
        courses: dashboardCourses
      }))

      return responseData
    }

    try {
      const promises = await Promise.allSettled(graphqlPromises)
      const data = callback(promises)
      return {
        data
      }
    } catch (error) {
      console.error(error)
        const msg = error?.message ?? error 
        return {
          error: typeof msg === 'string' ? msg : `Unable to get assignments for courses` 
      } 
      
    }
  },
};

export default source;
