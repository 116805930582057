import { useEffect, useRef } from "react";
import Main from "../../Main/components";
import { MAIN_ID, OSU_NAV_ID } from "../../util/constants";
import {
  withOSUTheming,
  useScrollToHash,
  withTableOfContents,
  withDarkMode,
  withPageHeading,
  useFooterWithTOC,
  Mui
} from "@osu/react-ui";
import { Navbar as OSUNavbar } from "@osu/navbar";
import { AppProvider } from "../context";
import Footer from "@osu/footer";
import Navbar from "../../Navbar/components";
import useCssVariableUpdater from "../../Common/hooks/useCssVariableUpdater";
import { footerProps, navOverrideStyles, linkOverrides } from "../overrides";
import useGridSizing from "../../Dashboard/hooks/useGridSizing";
import EmergencyAlert from "../../EmergencyAlert/components";
import Chatbot, { OSU_CHATBOT_ID } from "../../Chatbot/components";
import withAmplifyCacheValues from "../hocs/withAmplifyCacheValues";
import '../styles/index.scss'
import useOnIdle from "../hooks/useOnIdle";

const darkModeClassName = 'dark-mode-enabled'

function App(props = {}) {
  const { darkMode, FooterProps } = props;
  const { ref } = useScrollToHash();
  const footerRef = useRef()
  useFooterWithTOC({ ref: footerRef })
  const { padding } = useGridSizing()
  let cls = 'App'
  if(darkMode) cls += ` ${darkModeClassName}`
  
  useOnIdle()

  useEffect(() => {
    const classList = document.body.classList
    const alreadyContains = document.body.classList.contains(darkModeClassName)
    if(darkMode && !alreadyContains) {
      classList.add(darkModeClassName)
    } else if (!darkMode && alreadyContains) {
      classList.remove(darkModeClassName)
    }
  }, [darkMode])

  return (
    <Mui.Box  sx={(theme) => navOverrideStyles(theme, padding)} className={cls} ref={ref}>
      <EmergencyAlert />
      <OSUNavbar chatHref={"#" + OSU_CHATBOT_ID}  mainHref={"#"+MAIN_ID} linkOverrides={linkOverrides} darkMode={darkMode}/>
      <Navbar id={OSU_NAV_ID} />
      <Main className="anchor-link" id={MAIN_ID} />
      <Chatbot />
      <Footer darkMode={darkMode} ref={footerRef} {...footerProps} {...FooterProps ?? {}} />
    </Mui.Box>
  );
}

const TOCWrappedApp = withTableOfContents(App);

function Provider(props = {}) {
  const { darkMode, setDarkModeOverride, FooterProps } = props;
  const { navHeight } = useCssVariableUpdater();
  const appContext = {
    navHeight,
    darkMode,
    setDarkModeOverride
  };

  return (
    <AppProvider value={appContext}>
      <TOCWrappedApp darkMode={darkMode} navHeight={navHeight} FooterProps={FooterProps} />
    </AppProvider>
  );
}

export default withAmplifyCacheValues(withDarkMode(
  withOSUTheming(withPageHeading(Provider))
));
