import React, { Fragment } from "react";
import Heading from "../../../Dashboard/components/Resources/Heading";
import withStyling from "../../hocs/withStyling";
import { Mui } from "@osu/react-ui";
import { LEARN_MORE } from "../../../util/constants";
import { linkablePathValues } from "../../../Routes/paths";
import { Link } from "react-router-dom";
import ResourceList from "../Lists/Resources";

function Resources({ show }) {
  const headingId = 'resources-mental-health-dash'
  return (
    <Fragment>
      <Heading id={headingId} subheading="We're Here to Help">Get Support for Mental Health & Wellness</Heading>
      <ResourceList aria-labelledby={headingId} show={show} />
      <Mui.Divider />
      <Mui.Button
        variant="outlined"
        className="margin-top-2 align-self-end"
        to={linkablePathValues.wellness}
        component={Link}
        aria-describedby={headingId}
      >
        {LEARN_MORE}
      </Mui.Button>
    </Fragment>
  );
}

export default withStyling(Resources);
