import { setHRStore } from "../../graphql/slices/hrProfileData";
import { getHRProfileData } from "../enums";
import { format } from 'date-fns'

const CONSTANTS = {
    get: getHRProfileData
};

const get = `
  ${CONSTANTS.get} {
    data {
      isLeaveEligible
      isTimesheetEmployee
      timesheet {
        regular
        timeOff
        overTime
        compTime
        shiftTime
        holidayBenefit
        holidayWorked
        totalReportedHours
        weekOf
        positionTitle
      }
      leave {
        balances {
          current
          description
          pending
        }
        asOf
      }
      pay {
        date
        net
      }
    }
  }
`;

const timesheetMapping = {
  regular: 'Regular',
  timeOff: 'Time Off',
  overTime: 'Overtime',
  compTime: 'Comp Time Earned',
  shiftTime: 'Shift Time',
  holidayBenefit: 'Holiday Benefit',
  holidayWorked: 'Holiday Worked',
  totalReportedHours: 'Total Reported Hours',
}

const parseTimesheet = (timesheet = []) => {
  let sheets = []
  if(!timesheet?.length) {
    return sheets
  }
  timesheet.forEach(({ weekOf, positionTitle, ...time }) => {
    const entries = Object.entries(timesheetMapping ?? {})
    if(positionTitle) {
      const newRecord = {
        weekOf,
        fields: []
      }

      entries.forEach(([key, value]) => {
        const validatedInt = time[key] ? parseFloat(time[key]).toFixed(2) : 0
        if(validatedInt > 0) {
          const label = value
          const hours = validatedInt
          newRecord.fields.push({
            label,
            hours
          })
        }

      })
      const positionExistsAlready = sheets.find(job => job.positionTitle === positionTitle)
      if(positionExistsAlready) {
        if(!positionExistsAlready.records) {
          positionExistsAlready.records = []
        }
        positionExistsAlready.records.push(newRecord)
      } else {
        sheets.push({
          positionTitle,
          records: [newRecord]
        })
      }
    }
  })
  return sheets
}

const transformResponse = (response = {}, { dispatch }) => {
  if(response?.error || !response?.data?.[getHRProfileData]) {
    throw (response?.error ?? "No data found")
  }
  const { pay, leave = {}, timesheet = [], isLeaveEligible, isTimesheetEmployee } = response?.data?.[getHRProfileData]?.data ?? {}
  const eligible = {
    pay: Boolean(pay?.date),
    leave: Boolean(isLeaveEligible && leave.asOf && leave?.balances?.length),
    timesheet: Boolean(isTimesheetEmployee)
  }

  let chips = {}
  const reduxPayload = {}
  if(eligible.pay) {
    chips.pay = {
      chips: [
        {
          title: 'Payment Date',
          snippet: pay.date
        }
      ]
    }
    reduxPayload.pay = pay?.net
  }
  if(eligible.leave) {
    const formatted = format(leave.asOf, 'MM/dd/yyyy')
    chips.leave = {
      chips:  [{
        title: 'As Of',
        snippet: formatted
      }]
    }

    reduxPayload.balances = leave?.balances
  }
  if(eligible.timesheet) {
    reduxPayload.timesheet = parseTimesheet(timesheet) ?? []
  }

  reduxPayload.chips = chips
  
  dispatch(setHRStore(reduxPayload))
  return {
    data: {
      ...response.data,
      eligible
    }
  }
}

const source = {
    CONSTANTS,
    QUERIES: {
      get
    },
    transformResponse
  };
  
  export default source;