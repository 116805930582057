import { useSelector } from "react-redux";
import { selectAuthentication, selectAuthenticationConditions } from "../../services/authorization/slices";
import { compareAuthentication } from "../../services/util/selectorEquality";

function useRoleAuthentication() {
  const { roles = [], ...rest } = useSelector(state => {
    return selectAuthentication(state)
  }, compareAuthentication);

  const conditions = useSelector(selectAuthenticationConditions)

  return {
    ...conditions,
    ...rest,
    roles
  }
}

export default useRoleAuthentication;