import { useNavigate } from "react-router-dom";
import { authorizationRoutes } from "../../Routes/paths";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";
import { selectUserIsLoggedIn } from "../../services/authorization/slices";

const TIMEOUT = 60000 * (parseInt(process.env.REACT_APP_TIMEOUT) || 120);

const useOnIdle = () => {
  const isLoggedIn = useSelector(selectUserIsLoggedIn)
  const navigate = useNavigate()

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    isLoggedIn && navigate(authorizationRoutes.logout)
  };
  
  const { getLastActiveTime } = useIdleTimer({
    timeout: TIMEOUT,
    onIdle: handleOnIdle,
  });
}

export default useOnIdle