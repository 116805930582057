import { createSlice } from '@reduxjs/toolkit'
import { ROLE_CONDITIONS, STATUSES } from '../../util/constants'
import { transformUser } from '../../Authentication/transform'
import {  graphqlApi } from '../graphql/api'
import { compareUserRolesToAllowedRoles } from '../../util/functions'
import { selectDashboardRoleType } from '../graphql/slices/dashboard'
import { UNAUTH_CONSTANT } from '../../Dashboard/util/unauthSections'

const initialState = { 
  status: STATUSES.IDLE,
  user: {
  },
  roles: undefined
}

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    status(state, action) {
      state.status = action?.payload
      if(action?.payload === STATUSES.CLEARED) {
        state.user = {}
      }
    },
    user(state, action) {
      const newOSUId = action?.payload?.osuid 
      const existingOSUId = state?.user?.osuid
      let allowUserToBeSet = newOSUId 
        && state.status !== STATUSES.CLEARED

      if(allowUserToBeSet && (newOSUId !== existingOSUId)) {
        state.user = transformUser(action?.payload)
        state.status = STATUSES.SUCCESS
      }
    },
    setRoles(state, action) {
      if(action?.payload?.roles && state.user) {
        state.roles = action?.payload?.roles ?? []
      }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      graphqlApi.endpoints.getAffiliations.matchPending,
      (state) => {
        state.status = STATUSES.LOADING;
      }
    );
    builder.addMatcher(graphqlApi.endpoints.getAffiliations.matchFulfilled,
      (state) => {
        state.status = STATUSES.SUCCESS;
      }
    );
  }
})

const selectAuthentication = (state)  => {
  const {user = {}, status,roles} = state?.authentication || {}
  const isSuccess = STATUSES.SUCCESS === status

  return {
    user,
    status,
    cognitoGroups: user.cognitoGroups ?? [],
    isLoading: STATUSES.LOADING === status,
    isError: STATUSES.ERROR === status,
    isSuccess,
    isCleared: STATUSES.CLEARED === status,
    isIdle: STATUSES.IDLE === status,
    roles: roles?.length ? roles : user.cognitoGroups,
    isLoggedIn: isSuccess && !!user?.osuid
  }
  
}

const selectAuthenticationStatus = (state)  => {
  return state?.authentication?.status
}

const selectAuthenticationRoleComparison = (state = {}, allowedRoles = [], test) => {
  const { roles } = selectAuthentication(state)

  if(!roles?.length) {
    return !allowedRoles?.length
  }
    
  return compareUserRolesToAllowedRoles(roles, allowedRoles, test)
}

const selectAuthenticationConditions = (state = {}) => {
  const isLoggedIn = selectUserIsLoggedIn(state)
  const isStudent = isLoggedIn && selectAuthenticationRoleComparison(state, ROLE_CONDITIONS.student)
  const isStaffFaculty = isLoggedIn && selectAuthenticationRoleComparison(state, ROLE_CONDITIONS.staffFaculty)
  const isAlumni = isLoggedIn && selectAuthenticationRoleComparison(state, ROLE_CONDITIONS.alumni)
  const isNonStandardEmployee = isLoggedIn && (!isStaffFaculty && !isStudent)
    && selectAuthenticationRoleComparison(state, ROLE_CONDITIONS.nonStandardEmployee)

  return {
    isStudent,
    isStaffFaculty,
    isStaffFacultyNotStudent: !isStudent && isStaffFaculty,
    isStaffFacultyAndStudent: isStudent && isStaffFaculty,
    isAlumni,
    isNonStandardEmployee
  }
}

const selectUserIsLoggedIn = (state = {}) => {
  return selectAuthentication(state)?.isLoggedIn
}

const selectIsUnauthenticated = (state) => {
    const { isIdle, isError, isCleared } = selectAuthentication(state)
    const roleTypeUnauth = selectDashboardRoleType(state) === UNAUTH_CONSTANT
    return roleTypeUnauth && (isIdle || isError || isCleared)
}

const { status: setAuthenticationStatus, user: setAuthenticationUser, setRoles } = authenticationSlice.actions
const { reducer } = authenticationSlice
export { 
    setAuthenticationStatus,
    setAuthenticationUser,
    selectAuthentication,
    setRoles,
    selectAuthenticationStatus,
    selectAuthenticationRoleComparison,
    selectAuthenticationConditions,
    selectUserIsLoggedIn,
    selectIsUnauthenticated,
    reducer as default
}