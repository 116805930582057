
import { useWellnessData } from "../../hooks/useWellnessData";
import List from "../../../Common/components/Articles/List";
import { mergeSxStyles } from "../../../styles/util";
import { listReset } from "../../../styles/commonSx";

const createLoading = (amount = 18) => {
  const arr = [...Array(amount)];
  return arr.map((empty, idx) => ({
    identifier: `resource-loading-${idx}`,
    loading: true,
  }));
};

function ResourceList({ show, sx, ...rest }) {
  const { featured, isLoading } = useWellnessData();
  let resources = [];
  if (featured?.resources?.length) {
    resources = featured.resources
  } else if (!resources?.length && isLoading) {
    resources = createLoading(show)
  }
  const resourcesToShow = show ? resources.slice(0, show) : resources;

  return (
    <List aria-labelledby={rest["aria-labelledby"]} sx={mergeSxStyles(listReset, sx)} allowLink={false} disablePadding articles={resourcesToShow} />
  );
}

export default ResourceList;
